import React, { useCallback } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';

import './cf-input.scss';
import classNames from 'classnames';

export enum CFInputType {
  Number = 'number',
  Text = 'text',
  Password = 'password',
}

interface BaseProps {
  placeholder?: string;
  message?: string;
  disabled?: boolean;
  icon?: IconDefinition;
  fontSize?: 'sm' | 'md' | 'lg' | 'xl' | 'xxl';
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  onAccepted?: () => void;
  min?: number;
  className?: string;
  name?: string;
}

interface PropsWithNumber extends BaseProps {
  type?: CFInputType.Number;
  value?: number;

  defaultValue?: number;
}

interface PropsWithText extends BaseProps {
  type?: CFInputType.Text | CFInputType.Password;
  value?: string;
  defaultValue?: string;
}

type Props = PropsWithNumber | PropsWithText;

const ENTER_CODE = 'Enter';

const CFInput = React.forwardRef<HTMLInputElement, Props>(function CFInput(
  {
    type = CFInputType.Text,
    name,
    message = '',
    defaultValue,
    icon,
    placeholder,
    disabled = false,
    fontSize,
    onChange,
    onAccepted,
    min = Number.NEGATIVE_INFINITY,
    value,
    className,
  }: Props,
  ref
) {
  const handleKeyDown = useCallback((evt: React.KeyboardEvent) => {
    if (!onAccepted) {
      return;
    }

    if (evt.key === ENTER_CODE) {
      onAccepted();
    }
  }, []);

  return (
    <div className={classNames('cf-input', className)}>
      <div className="cf-input-container">
        <input
          name={name}
          placeholder={placeholder}
          onKeyDown={handleKeyDown}
          className={`cf-input ${icon ? 'icon' : ''} font-${fontSize}`}
          value={value}
          ref={ref}
          type={type}
          disabled={disabled}
          onChange={onChange}
          defaultValue={defaultValue}
          min={min}
        />
        {icon && <FontAwesomeIcon icon={icon} size="sm" className="input-icon" />}
      </div>
      {message && <div className="message"> {message} </div>}
    </div>
  );
});

export default CFInput;
