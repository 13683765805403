import React, { useEffect, useMemo, useState } from 'react';

import { AlgorithmType, InterventionViewExtended } from 'services/intervention/intervention.types';
import { useParams } from 'react-router-dom';
import { CFRoutes } from 'routes';
import InterventionABMetrics from './ab-metrics';
import InterventionBanditMetrics from './bandit-metrics';
import { useServicesContext } from 'hooks/useServicesContext';
import useCFNavigation from 'hooks/useCFNavigation';
import { CFNavList } from 'components/CFNavList';
import { Tabs, monitoringByTab } from '../tabs';
import InterventionRestlessBanditMetrics from './restless-bandit-metrics';
import InterventionBanditV2Metrics from './bandit-v2-metrics';

const InterventionMetrics = () => {
  const { interventionService, traitSessionService: traitService } = useServicesContext();
  const params = useParams();
  const navigate = useCFNavigation();

  const [intervention, setIntervention] = useState<InterventionViewExtended>();

  const interventionId = useMemo(() => {
    return parseInt(params.id as string);
  }, []);

  useEffect(() => {
    (async () => {
      try {
        const intv = await interventionService.getView(interventionId);
        setIntervention(intv);
      } catch (err: any) {
        navigate(CFRoutes.intervention);
      }
    })();
  }, [interventionId]);

  if (!intervention) {
    return <div></div>;
  }

  let metricsSection;
  if (intervention?.intervention.algo_policy.type === AlgorithmType.ABTest) {
    metricsSection = <InterventionABMetrics traitService={traitService} interventionService={interventionService} />;
  }

  if (intervention?.intervention.algo_policy.type === AlgorithmType.Bandit) {
    metricsSection = (
      <InterventionBanditMetrics traitService={traitService} interventionService={interventionService} />
    );
  }

  if (intervention?.intervention.algo_policy.type === AlgorithmType.BanditV2) {
    metricsSection = (
      <InterventionBanditV2Metrics traitService={traitService} interventionService={interventionService} />
    );
  }

  if (intervention?.intervention.algo_policy.type === AlgorithmType.RestlessBandit) {
    metricsSection = (
      <InterventionRestlessBanditMetrics traitService={traitService} interventionService={interventionService} />
    );
  }

  return (
    <div className="intervention-metrics">
      <CFNavList
        titles={Object.values(Tabs)}
        selected={Tabs.Metrics}
        onClick={(selectedTab) => navigate(monitoringByTab[selectedTab].replace(':id', params.id as string))}
      />
      {metricsSection}
    </div>
  );
};

export default InterventionMetrics;
