import React, { useEffect, useRef, useState } from 'react';

import { UserDetailedInfo } from 'types';
import { CFRoutes } from 'routes';
import useCFNavigation from 'hooks/useCFNavigation';
import { useToast } from 'hooks';

import { ProjectRole, Role } from 'services/admin/users/user.types';
import { CFRole } from 'domain/general.types';

import { invite, listRoles } from 'services/admin/users/users.repo';

import { AuthAction, isAllowedTo } from 'services/authorization.service';
import { getOrganization } from 'services/session/session.service';

import AdminLayout from 'views/admin/layout';
import { Tabs } from 'views/admin/tabs';

import { ToastType } from 'components/CFToast/types';
import CFButton from 'components/buttons/CFButton';
import CFInput, { CFInputType } from 'components/CFInput';
import CFTitledComponent from 'components/CFTitledComponent';
import CFRolesEditor, { CFRolesEditorRef } from 'components/CFRolesEditor';

import './invite-user.scss';

const MIN_USERNAME_LENGTH = 8;

interface Props {
  userDetailedInfo: UserDetailedInfo;
  orgId: number;
}

const InviteUser = ({ userDetailedInfo, orgId }: Props) => {
  const navigate = useCFNavigation();
  const { addToast } = useToast();
  const [isLoading, setIsLoading] = useState(false);
  const rolesEditorRef = useRef<CFRolesEditorRef>(null);

  const projectRoles = [ProjectRole.User, ProjectRole.InterventionCreator];
  const [availableRoles, setAvailableRoles] = useState<Role[]>([]);
  const [availableAdminRoles, setAvailableAdminRoles] = useState<Role[]>([]);

  useEffect(() => {
    (async () => {
      const roles = await listRoles();

      setAvailableRoles(roles.roles);
      setAvailableAdminRoles(roles.admin_roles);
    })();
  }, []);

  if (isAllowedTo(AuthAction.AddViewerRole)) {
    projectRoles.push(ProjectRole.Viewer);
  }

  const handleCancelInvite = async () => {
    navigate(CFRoutes.admin_users);
  };

  const handleInvite = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsLoading(true);
    const username = (e.target as HTMLFormElement).email.value;
    const password = (e.target as HTMLFormElement).password.value;

    const newRoles = rolesEditorRef.current?.value();

    if (!newRoles) {
      return;
    }

    if (username.length < MIN_USERNAME_LENGTH) {
      addToast(`Username must be at least ${MIN_USERNAME_LENGTH} characters long`, ToastType.WARNING);
      setIsLoading(false);
      return;
    }

    try {
      await invite(username, password, newRoles);
      addToast('User invited successfully', ToastType.SUCCESS);
    } catch (err) {
      addToast(`Error inviting user: ${(err as any).message}`, ToastType.ERROR);
      return;
    } finally {
      setIsLoading(false);
    }

    navigate(CFRoutes.admin_users);
  };

  const org = getOrganization();

  return (
    <AdminLayout tab={Tabs.Users}>
      <form onSubmit={handleInvite}>
        <div className="invite-user-container">
          <CFTitledComponent title={'Email'} className="input">
            <CFInput name="email" />
          </CFTitledComponent>

          <CFTitledComponent title={'Password'} className="input">
            <CFInput name="password" type={CFInputType.Password} />
          </CFTitledComponent>

          <div className="section-title"> Organization and project roles </div>

          <CFRolesEditor
            ref={rolesEditorRef}
            orgprojs={userDetailedInfo.available_orgprojs}
            orgId={orgId}
            currentRoles={{
              oid: parseInt(org || '-1'),
              org_roles: [],
              proj_roles: {},
            }}
            currentAdminRoles={[]}
            availableRoles={availableRoles}
            availableAdminRoles={availableAdminRoles}
            includeAdmin={false}
          />

          <div className="controls">
            <CFButton value={'Cancel'} onClick={handleCancelInvite} />
            <CFButton value={'Invite'} onClick={() => ({})} role={CFRole.Primary} isLoading={isLoading} />
          </div>
        </div>
      </form>
    </AdminLayout>
  );
};

export default InviteUser;
