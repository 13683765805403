const moveElementUp = <T>(list: T[], index: number): T[] => {
  const newList = [...list];

  if (index === 0 || index > list.length - 1 || index < 0) {
    return newList;
  }

  const temp = list[index - 1];
  newList[index - 1] = list[index];
  newList[index] = temp;

  return newList;
};

const moveElementDown = <T>(list: T[], index: number): T[] => {
  const newList = [...list];

  if (index >= list.length - 1 || index < 0) {
    return newList;
  }

  const temp = list[index + 1];
  newList[index + 1] = list[index];
  newList[index] = temp;

  return newList;
};

const findNewInArray = (prev: string[], current: string[]): string[] => {
  const counters: Record<string, number> = {};

  current.forEach((str) => (counters[str] = 0));
  prev.forEach((str) => (counters[str] = 1));

  return Object.entries(counters)
    .filter((keyValue) => keyValue[1] === 0)
    .map((keyValue) => keyValue[0]);
};

const findDeletedInArray = (prev: string[], current: string[]): string[] => {
  const counters: Record<string, number> = {};

  prev.forEach((str) => (counters[str] = 0));
  current.forEach((str) => (counters[str] = 1));

  return Object.entries(counters)
    .filter((keyValue) => keyValue[1] === 0)
    .map((keyValue) => keyValue[0]);
};

export { moveElementUp, moveElementDown, findNewInArray, findDeletedInArray };
