import React, { useCallback, useEffect, useMemo, useState } from 'react';
import debounce from 'debounce';
import intersection from 'lodash.intersection';

import { CFRole, Module } from 'domain/general.types';
import { AggLevel } from 'domain/stats.types';
import { Trait, TraitCategory } from 'domain/traits.types';

import { getDataType, getDisplayName, getSourceDataType, parsePTR, PtrFields } from 'services/traits/helpers.traits';

import CFTitledComponent from 'components/CFTitledComponent';
import CFTable, { Column, ColumnType, TableType } from 'components/CFTable';
import CFButton from 'components/buttons/CFButton';
import CFInput from 'components/CFInput';
import CloudPicker from 'components/CloudPicker';
import CFTrashButton from 'components/buttons/CFTrashButton';

import CFTraitItem from '../CFTraitItem';
import useMultiSelect from './hooks/useOptionState';

import CFTitledSection, { SectionAction, TitleSize } from 'components/CFTitledSection';

import './trait-explorer.scss';

interface ExtendedTrait extends Trait {
  parsedPtr: PtrFields;
}

interface Props {
  traits: Trait[];
  defaultSelectedTraits?: Trait[];
  isMulti?: boolean;
  onCancel: () => void;
  onTraitSelected: (traits: Trait[]) => void;
}

const TraitExplorer = ({ isMulti = false, traits, defaultSelectedTraits = [], onCancel, onTraitSelected }: Props) => {
  const [filteredTraits, setFilteredTraits] = useState(traits);

  const { selectedItems: aggLevel, setItems: setAggLevel } = useMultiSelect<string>({});
  const { selectedItems: dataType, setItems: setDataType } = useMultiSelect<string>({});
  const { selectedItems: categories, setItems: setCategories } = useMultiSelect<TraitCategory>({});
  const { selectedItems: units, setItems: setUnits } = useMultiSelect<string>({});
  const { selectedItems: module, setItems: setModule } = useMultiSelect<Module>({});
  const { selectedItems: subject, setItems: setSubject } = useMultiSelect<Module>({});
  const { selectedItems: window, setItems: setWindow } = useMultiSelect<string>({});

  const { selectedItems: selectedTraits, setItems: setSelectedTraits } = useMultiSelect<Trait>({
    isMulti,
    defaultSelected: defaultSelectedTraits,
  });

  const [search, setSearch] = useState('');

  const extendedTraits = useMemo(() => {
    return traits.map((trait) => ({ ...trait, parsedPtr: parsePTR(trait.addr.ptr) }));
  }, [traits]);

  const [availableDataTypes] = useState(() => {
    const availableTypes = traits.map((trait) => getSourceDataType(trait.addr));
    return Array.from(new Set(availableTypes));
  });

  const [availableUnits] = useState(() => {
    const allUnits = traits.map((trait) => trait.meta.unit);

    return Array.from(new Set(allUnits));
  });

  const [availableSubjects] = useState(() => {
    const allSubjects = traits.map((trait) => trait.meta.subject);

    return Array.from(new Set(allSubjects));
  });

  const [availableModules] = useState(() => {
    const allModules = traits.map((trait) => trait.meta.modules);

    return Array.from(new Set(allModules.flat().filter((item) => !!item)));
  });

  const availableWindows = useMemo(() => {
    const allWindows = extendedTraits.map((trait) => trait.parsedPtr.window);

    return Array.from(new Set(allWindows.flat().filter((item) => !!item)));
  }, [extendedTraits]);

  const hiddenSelectedTags = useMemo(() => {
    return [availableWindows, availableModules, availableSubjects, availableUnits, availableDataTypes]
      .filter((item) => item.length === 1)
      .flat();
  }, [availableWindows, availableModules, availableSubjects, availableUnits, availableDataTypes]);

  useEffect(() => {
    let filteredTraits: ExtendedTrait[] = extendedTraits;

    if (aggLevel.length) {
      filteredTraits = filteredTraits.filter((trait) => {
        return aggLevel.includes(trait.parsedPtr.aggLevel);
      });
    }

    if (dataType.length) {
      filteredTraits = filteredTraits.filter((trait) => {
        return dataType.includes(getDataType(trait.addr));
      });
    }

    if (categories.length) {
      filteredTraits = filteredTraits.filter((trait) => {
        return categories.includes(trait.parsedPtr.category);
      });
    }

    if (units.length) {
      filteredTraits = filteredTraits.filter((trait) => {
        return units.includes(trait.meta.unit);
      });
    }

    if (search.length) {
      filteredTraits = filteredTraits.filter(
        (trait) =>
          getDisplayName(trait).toLowerCase().includes(search) || trait.meta.description.toLowerCase().includes(search)
      );
    }

    if (module.length) {
      filteredTraits = filteredTraits.filter((trait) => {
        return intersection(trait.meta.modules, module).length !== 0;
      });
    }

    if (window.length) {
      filteredTraits = filteredTraits.filter((trait) => {
        return window.includes(trait.parsedPtr.window);
      });
    }

    setFilteredTraits(filteredTraits);
  }, [extendedTraits, aggLevel, dataType, categories, search, units, module, window]);

  const handleSelectUnit = useCallback((unit: string) => {
    setUnits(unit as string);
  }, []);

  const handleSelectModule = useCallback((module: string) => {
    setModule(module);
  }, []);

  const handleSelectCategory = useCallback((category: string) => {
    setCategories(category as TraitCategory);
  }, []);

  const handleSelectAggLevelCloud = useCallback((level: string) => {
    setAggLevel(level);
  }, []);

  const handleSelectSubject = useCallback((subject: string) => {
    setSubject(subject);
  }, []);

  const handleSelectType = useCallback((type: string) => {
    setDataType(type);
  }, []);

  const handleSelectWindow = useCallback((window: string) => {
    setWindow(window);
  }, []);

  const handleSave = useCallback(() => {
    if (!selectedTraits) {
      return;
    }

    onTraitSelected(selectedTraits);
  }, [selectedTraits]);

  const handleCancel = useCallback(() => {
    onCancel();
  }, []);

  const handleSelectedTrait = useCallback((row: any) => {
    setSelectedTraits(row);
  }, []);

  const handleSearchChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(evt.target.value.trim().toLowerCase());
  };

  const debouncedhandleSearchChange = useCallback(debounce(handleSearchChange, 200), []);

  const headers: Column[] = [
    {
      title: 'Trait',
      type: ColumnType.STRING,
      field: 'name',
      renderCell: (row) => <CFTraitItem addr={(row as Trait).addr} showAggLevel omitDisplayName />,
    },
    {
      title: 'Name',
      type: ColumnType.STRING,
      field: '',
      renderCell: (row) => getDisplayName(row as Trait),
    },
    {
      title: 'Description',
      type: ColumnType.STRING,
      field: 'meta.description',
    },
  ];

  const selectedHeaders = useMemo(() => {
    const temporaryHeaders = [...headers];

    if (isMulti) {
      temporaryHeaders.push({
        title: '',
        type: ColumnType.OBJECT,
        field: '',
        renderCell: (row: any) => <CFTrashButton onClick={() => handleSelectedTrait(row as Trait)} />,
      });
    }

    return temporaryHeaders;
  }, [isMulti]);

  const handleClearSelection = useCallback(() => {
    setSelectedTraits(null);
  }, []);

  return (
    <CFTitledSection title="Trait explorer" className="trait-explorer" onClose={handleCancel} underlined>
      <SectionAction>
        <CFButton value={'Save'} onClick={handleSave} role={CFRole.Cyan} disabled={!selectedTraits} />
      </SectionAction>

      <div className="trait-explorer__controls">
        <CFTitledComponent title="Search" className="trait-explorer__controls__search">
          <CFInput placeholder="search" onChange={debouncedhandleSearchChange} />

          <CloudPicker
            tags={hiddenSelectedTags.map((value) => ({
              value,
              label: value,
              selected: true,
            }))}
          />
        </CFTitledComponent>

        {availableSubjects.length > 1 && (
          <CFTitledComponent title="Subject">
            <CloudPicker
              tags={availableSubjects.map((value) => ({
                value,
                label: value,
                selected: subject.includes(value),
              }))}
              onClick={handleSelectSubject}
            />
          </CFTitledComponent>
        )}

        <CFTitledComponent title="Aggregation level">
          <CloudPicker
            tags={Object.values(AggLevel).map((value) => ({
              value,
              label: value,
              selected: aggLevel.includes(value),
            }))}
            onClick={handleSelectAggLevelCloud}
          />
        </CFTitledComponent>

        {availableWindows.length > 1 && (
          <CFTitledComponent title="Monitoring window">
            <CloudPicker
              tags={availableWindows.map((value) => ({
                value,
                label: value,
                selected: window.includes(value),
              }))}
              onClick={handleSelectWindow}
            />
          </CFTitledComponent>
        )}

        {availableDataTypes.length > 1 && (
          <CFTitledComponent title="Data type">
            <CloudPicker
              tags={availableDataTypes.map((value) => ({
                value,
                label: value,
                selected: dataType.includes(value),
              }))}
              onClick={handleSelectType}
            />
          </CFTitledComponent>
        )}

        {categories.length > 1 && (
          <CFTitledComponent title="Category">
            <CloudPicker
              onClick={handleSelectCategory}
              tags={Object.values(TraitCategory).map((value) => ({
                value,
                label: value,
                selected: categories.includes(value),
              }))}
            />
          </CFTitledComponent>
        )}

        {availableUnits.length > 1 && (
          <CFTitledComponent title="Unit">
            <CloudPicker
              onClick={handleSelectUnit}
              tags={availableUnits.map((value) => ({
                value,
                label: value || '<empty>',
                selected: units.includes(value),
              }))}
            />
          </CFTitledComponent>
        )}

        {availableModules.length > 1 && (
          <CFTitledComponent title="Module">
            <CloudPicker
              onClick={handleSelectModule}
              tags={availableModules.map((value) => ({
                value,
                label: value,
                selected: module.includes(value),
              }))}
            />
          </CFTitledComponent>
        )}
      </div>

      <CFTitledSection
        title={`Selected traits (${selectedTraits.length})`}
        className="trait-explorer__selected"
        size={TitleSize.Small}
        nested
      >
        <SectionAction>
          <CFButton role={CFRole.Borderless} value={'Clear'} onClick={handleClearSelection} />
        </SectionAction>

        <CFTable
          headers={selectedHeaders}
          data={selectedTraits}
          emptyLabel="No Selected Traits"
          skipHeader={true}
          variant={TableType.Secondary}
          // onSelectRow={handleSelectedTrait}
        />
      </CFTitledSection>

      <CFTitledComponent
        title={`Available traits (${filteredTraits.length} / ${traits.length})`}
        className="trait-explorer__data"
      >
        <CFTable
          headers={headers}
          data={filteredTraits}
          emptyLabel="No Available Traits"
          skipHeader={true}
          onSelectRow={handleSelectedTrait}
        />
      </CFTitledComponent>
    </CFTitledSection>
  );
};

export default TraitExplorer;
