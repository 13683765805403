import React, { useCallback } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';

import './cf-chip.scss';
import classNames from 'classnames';

export enum CFChipRole {
  Primary = 'primary',
  Secondary = 'secondary',
}

export enum CFChipSize {
  Small = 'small',
  Large = 'large',
}

interface Props {
  value: string;
  role?: CFChipRole;
  removable?: boolean;
  size?: CFChipSize;
  onRemove?: (value: string) => void;
  onSelect?: (value: string) => void;
}

const CFChip = ({
  onSelect,
  onRemove,
  role = CFChipRole.Primary,
  size = CFChipSize.Large,
  value,
  removable = true,
}: Props) => {
  const handleRemove = useCallback((evt: React.MouseEvent<SVGSVGElement>) => {
    evt.stopPropagation();

    if (!onRemove) {
      return;
    }

    onRemove(value);
  }, []);

  const handleClick = useCallback(() => {
    onSelect?.(value);
  }, []);

  return (
    <div key={value} className={classNames('cf-chip', role, size, { clickable: !!onSelect })} onClick={handleClick}>
      <span>{value}</span>
      {removable && (
        <FontAwesomeIcon
          className="cf-chip__cross"
          onClick={handleRemove}
          icon={icon({ name: 'xmark', style: 'solid' })}
        />
      )}
    </div>
  );
};

export default CFChip;
