import React from 'react';

import { InterventionViewExtended } from 'services/intervention/intervention.types';
import TraitService from 'services/traits/traitSession.service';

import { Steps } from 'views/intervention/interventions/NewIntervention';

import CFTitledSection from 'components/CFTitledSection';
import MetricsSection from './components/MetricsSection';

import './metrics.scss';

interface Props {
  intervention: InterventionViewExtended;
  traitService: TraitService;
  step: Steps;
  className?: string;
}

const BanditMetricsViewer = ({ intervention, traitService, step, className = '' }: Props) => {
  const objective =
    intervention.intervention.metric_policy.bandit &&
    traitService.getTraitFromAddr(intervention.intervention.metric_policy.bandit.objective);
  const supporting =
    intervention.intervention.metric_policy.bandit?.supporting
      .map((addr) => traitService.getTraitFromAddr(addr))
      .filter((trait) => trait !== undefined) || [];

  const staticCtx = intervention.intervention.metric_policy.bandit?.static_ctx || [];
  const dynamicCtx = intervention.intervention.metric_policy.bandit?.dynamic_ctx || [];

  const contextual =
    [...staticCtx, ...dynamicCtx]
      .map((addr) => traitService.getTraitFromAddr(addr))
      .filter((trait) => trait !== undefined) || [];

  return (
    <CFTitledSection title="Metrics" className={`metrics-definition metrics ${className}`} id={step}>
      <MetricsSection traits={objective ? [objective] : []} title="Primary Metric" />
      <MetricsSection traits={supporting} title="Supporting metrics" />
      <MetricsSection traits={contextual} title="Contextual metrics" />
    </CFTitledSection>
  );
};

export default BanditMetricsViewer;
