import React, { useEffect, useMemo, useState } from 'react';

import { Trait, TraitCategory } from 'domain/traits.types';

import { Tag, TagSize, TagTypes } from 'components/Tag';

import { useServicesContext } from 'hooks/useServicesContext';

import {
  getAggLevel,
  getDataType,
  getDisplayName,
  getTraitCategory,
  getTraitSubject,
} from 'services/traits/helpers.traits';

import { tableColors, TraitItemProps } from '../traits.common';

import { AggLevel } from 'domain/stats.types';

import './cf-trait-item.scss';

const CFTraitItem = ({ addr, showAggLevel, omitDisplayName, disabled }: TraitItemProps) => {
  const { traitSessionService: traitService } = useServicesContext();
  const [trait, setTrait] = useState<Trait>();

  const tableType = useMemo(() => getTraitCategory(addr), [addr]);
  const subject = useMemo(() => getTraitSubject(addr), [addr]);
  const aggLevel = useMemo(() => getAggLevel(addr), [addr]);
  const displayName = useMemo(() => getDisplayName(trait), [trait]);

  const displayDateType = useMemo(() => {
    if (!trait) {
      return '';
    }

    return getDataType(trait.addr);
  }, [trait]);

  useEffect(() => {
    (async () => {
      let trait: Trait;

      if (typeof addr === 'object') {
        trait = await traitService.getTraitFromAddr(addr);
      } else {
        trait = await traitService.getTraitDefinition(addr);
      }

      setTrait(trait);
    })();
  }, [addr]);

  const color = !disabled ? tableColors[tableType] : undefined;

  return (
    <div className="cf-trait-item">
      <Tag text={tableType} type={TagTypes.Color} color={color} size={TagSize.extrasmall} />

      <Tag text={subject} type={TagTypes.SwitchedColors} color={color} size={TagSize.extrasmall} />
      <Tag text={displayDateType} color={color} type={TagTypes.SwitchedColors} size={TagSize.extrasmall} />
      {showAggLevel && aggLevel !== AggLevel.NA && (
        <Tag text={aggLevel} color={color} type={TagTypes.SwitchedColors} size={TagSize.extrasmall} />
      )}
      {trait?.meta.mltf !== '' && (
        <Tag
          text={'mltf'}
          color={tableColors[TraitCategory.MLT]}
          type={TagTypes.SwitchedColors}
          size={TagSize.extrasmall}
        />
      )}
      {!omitDisplayName && <span className="cf-trait-item__display-name"> {displayName} </span>}
    </div>
  );
};

export default CFTraitItem;
