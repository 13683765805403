import React from 'react';

import { Steps } from 'views/intervention/interventions/NewIntervention';

import { InterventionViewExtended } from 'services/intervention/intervention.types';
import TraitService from 'services/traits/traitSession.service';

import CFTitledSection from 'components/CFTitledSection';
import MetricsSection from './components/MetricsSection';

import './metrics.scss';

interface Props {
  intervention: InterventionViewExtended;
  traitService: TraitService;
  step: Steps;
  className?: string;
}

const BanditV2MetricsViewer = ({ intervention, traitService, step, className = '' }: Props) => {
  const objective =
    intervention.intervention.metric_policy.banditv2?.objective
      .map((addr) => traitService.getTraitFromAddr(addr))
      .filter((trait) => trait !== undefined) || [];

  const supporting =
    intervention.intervention.metric_policy.banditv2?.supporting
      .map((addr) => traitService.getTraitFromAddr(addr))
      .filter((trait) => trait !== undefined) || [];

  const staticCtx = intervention.intervention.metric_policy.banditv2?.static_ctx || [];
  const dynamicCtx = intervention.intervention.metric_policy.banditv2?.dynamic_ctx || [];

  const contextual =
    [...staticCtx, ...dynamicCtx]
      .map((addr) => traitService.getTraitFromAddr(addr))
      .filter((trait) => trait !== undefined) || [];

  return (
    <CFTitledSection title="Metrics" className={`metrics-definition metrics ${className}`} id={step}>
      <MetricsSection
        title="Objective metrics"
        traits={objective}
        extendedInfo={intervention.intervention.metric_policy.banditv2?.weights}
      />
      <MetricsSection title="Supporting metrics" traits={supporting} />
      <MetricsSection title="Contextual metrics" traits={contextual} />
    </CFTitledSection>
  );
};

export default BanditV2MetricsViewer;
