import React from 'react';
import { useCallback } from 'react';

import { Configuration, PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';

import MicrosoftLoginButton from 'components/MicrosoftLogin';

interface Props {
  onReady: (token: string) => void;
  onError: () => void;
}

const MicrosoftLogin = ({ onReady, onError }: Props) => {
  const msalConfiguration: Configuration = {
    auth: {
      clientId: process.env.REACT_APP_MICROSOFT_CLIENT_ID || '',
      redirectUri: '/',
    },
  };
  const pca = new PublicClientApplication(msalConfiguration);

  const handleTokenAndLogin = useCallback(
    (idToken: string) => {
      onReady(idToken);
    },
    [onReady]
  );

  return (
    <MsalProvider instance={pca}>
      <MicrosoftLoginButton
        onClick={() => {
          pca
            .loginPopup()
            .then((response) => {
              if (response.idToken) {
                handleTokenAndLogin(response.idToken);
              } else {
                onError();
              }
            })
            .catch(() => {
              onError();
            });
        }}
      />
    </MsalProvider>
  );
};

export default MicrosoftLogin;
