import React, { forwardRef, useCallback, useImperativeHandle, useState } from 'react';

import { useServicesContext } from 'hooks/useServicesContext';
import useAvailableSubjects from '../useAvailableSubjects';

import { Trait, TraitSubject } from 'domain/traits.types';

import { createTraitCode, getDisplayName } from 'services/traits/helpers.traits';
import { Ptr } from 'services/cohort/cohort.types.api';
import { MsgTmplCfg, PairRankType, TemplateType } from 'services/nudging/nudge.types';

import TraitItem from 'components/CFSelect/common/TraitItem';
import TraitInputContainer from 'components/CFSelect/common/TraitInputContainer';
import CFSelect, { Option } from 'components/CFSelect';
import CFTitledComponent from 'components/CFTitledComponent';

export interface TraitsConfigurationRef {
  value: () => MsgTmplCfg;
}

interface TraitsConfigurationProps {
  traits: Trait[];
  defaultValue?: MsgTmplCfg;
  onTraitSelected: (ptr: Ptr) => void;
}

const TraitsConfiguration = forwardRef<TraitsConfigurationRef, TraitsConfigurationProps>(function ItemPairConfiguration(
  { defaultValue, traits, onTraitSelected }: TraitsConfigurationProps,
  ref
) {
  const [subject, setSubject] = useState<TraitSubject>(defaultValue?.item_pair_cfg.item_type ?? TraitSubject.Drug);
  const { traitSessionService: traitService } = useServicesContext();
  const [selectedTraitsOptions, setSelectedTraitsOptions] = useState<Option[]>(() => {
    if (!defaultValue) {
      return [];
    }

    return defaultValue.traits.map((ptr) => ({
      value: ptr,
      label: getDisplayName(traitService.getTraitDefinition(ptr)),
    }));
  });
  const [traitSearch, setTraitSearch] = useState('');

  const availableSubjects = useAvailableSubjects();

  useImperativeHandle(ref, () => ({
    value() {
      return {
        tmpl_type: TemplateType.TraitsTemplate,
        item_pair_cfg: {
          item_type: subject,
          pair_rank_type: '' as PairRankType,
        },
        traits: selectedTraitsOptions.map(({ value }) => value),
      };
    },
  }));

  const handleSelectSubject = useCallback((subjectOption: Option) => {
    setSubject(subjectOption.value as TraitSubject);
  }, []);

  const handleTraitSelected = useCallback(
    async (option: Option) => {
      const newSelectedTraits = selectedTraitsOptions.filter(({ value }) => value !== option.value);

      if (selectedTraitsOptions.length === newSelectedTraits.length) {
        newSelectedTraits.push(option);
      }

      setSelectedTraitsOptions(newSelectedTraits);
      onTraitSelected(option.value);
    },
    [selectedTraitsOptions]
  );

  return (
    <div className="message-options">
      <CFTitledComponent title="Traits">
        <CFSelect
          testId="nudge-message-traits-select"
          onSelected={handleTraitSelected}
          options={(traits || [])
            .map((trait) => ({
              value: createTraitCode(trait.addr),
              label: getDisplayName(trait),
              meta: {
                trait,
              },
            }))
            .filter((value) => value.label.toLowerCase().includes(traitSearch))}
          searchable
          onSearch={setTraitSearch}
          isMulti
          Item={TraitItem}
          InputContainer={TraitInputContainer}
          value={selectedTraitsOptions}
        />
      </CFTitledComponent>

      <CFTitledComponent title="Subject">
        <CFSelect
          value={{
            label: subject,
            value: subject,
          }}
          options={availableSubjects?.map((subject) => ({ label: subject, value: subject })) || []}
          onSelected={handleSelectSubject}
        />
      </CFTitledComponent>
    </div>
  );
});

export default TraitsConfiguration;
