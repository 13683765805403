import React, { useCallback } from 'react';

import CFSelect from 'components/CFSelect';
import ScheduleSet from '../ScheduleSet';
import { Schedule, StartEndPair } from 'services/scheduling/schedulting.types.api';
import { useSchedulingContext } from '../context/useSchedulingContext';

import timezones from '../timezones.json';
import CFAddButton from 'components/buttons/CFAddButton';
import { CHART_COLORS } from 'styles/colors';

interface Props {
  defaultValue?: Schedule;
  editing?: boolean;
}

const RecurringSpecs = ({ defaultValue, editing = false }: Props) => {
  const { timezone, setTimezone, scheduleSetValues, setScheduleSetValues } = useSchedulingContext();

  const handleAddMoreSlots = () => {
    setScheduleSetValues({ ...scheduleSetValues, [new Date().valueOf()]: null });
  };

  const handleDeleteSlot = useCallback(
    (id: string) => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { [id]: _, ...rest } = scheduleSetValues;
      setScheduleSetValues(rest);
    },
    [scheduleSetValues]
  );

  const handleSetChange = useCallback(
    (id: string) => (pair: StartEndPair) => {
      setScheduleSetValues({ ...scheduleSetValues, [id]: pair });
    },
    [scheduleSetValues]
  );

  return (
    <div className="scheduling-builder-sets">
      <div className="group timezone">
        <div className="text-md">Timezone</div>
        <CFSelect
          options={Object.entries(timezones).map(([k, v]) => ({ value: v, label: k }))}
          value={{ value: timezone, label: timezone }}
          onSelected={(option) => setTimezone(option.value)}
          disabled={editing}
        />
      </div>

      {Object.entries(scheduleSetValues).map(([slotTs, defaultValueItem], i) => (
        <ScheduleSet
          key={slotTs}
          color={CHART_COLORS[i]}
          id={i + 1}
          defaultValue={defaultValueItem || undefined}
          onChange={handleSetChange(slotTs)}
          onRemove={() => handleDeleteSlot(slotTs)}
          timezone={timezone}
          editable={
            defaultValue && defaultValue.definition.recurring
              ? i >= defaultValue.definition.recurring.pairs.length
              : true
          }
        />
      ))}

      <CFAddButton value="Add schedules" onClick={handleAddMoreSlots} />
    </div>
  );
};

export default RecurringSpecs;
