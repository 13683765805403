import React, { forwardRef, useCallback, useImperativeHandle, useState } from 'react';

import CFSelect, { Option } from 'components/CFSelect';
import CFTitledComponent from 'components/CFTitledComponent';

import { MsgTmplCfg, PairRankType, TemplateType } from 'services/nudging/nudge.types';
import { TraitSubject } from 'domain/traits.types';

import useAvailableSubjects from '../useAvailableSubjects';

export interface ItemPairConfigurationRef {
  value: () => MsgTmplCfg;
}

const PairRankTypeLabels = {
  [PairRankType.PurchaseAmount]: 'Purchase amount',
  [PairRankType.PurchaseCount]: 'Purchase count',
  [PairRankType.PurchaseQuantity]: 'Purchase quantity',
  [PairRankType.Random]: 'Random',
};

interface ItemPairConfigurationProps {
  defaultValue?: MsgTmplCfg;
}

const ItemPairConfiguration = forwardRef<ItemPairConfigurationRef, ItemPairConfigurationProps>(
  function ItemPairConfiguration({ defaultValue }: ItemPairConfigurationProps, ref) {
    const [subject, setSubject] = useState<TraitSubject>(defaultValue?.item_pair_cfg.item_type ?? TraitSubject.Drug);
    const availableSubjects = useAvailableSubjects();

    const [pairRankOption, setPairRankOption] = useState<Option>(() => {
      if (defaultValue && defaultValue.item_pair_cfg.pair_rank_type !== undefined) {
        console.log(defaultValue.item_pair_cfg.pair_rank_type);
        return {
          value: defaultValue.item_pair_cfg.pair_rank_type,
          label: PairRankTypeLabels[defaultValue.item_pair_cfg.pair_rank_type as PairRankType] || '',
        };
      }

      return {
        label: PairRankTypeLabels[PairRankType.PurchaseAmount],
        value: PairRankType.PurchaseAmount,
      };
    });

    useImperativeHandle(ref, () => ({
      value() {
        return {
          tmpl_type: TemplateType.ItemPairTemplate,
          item_pair_cfg: {
            item_type: subject,
            pair_rank_type: (pairRankOption.value || PairRankType.PurchaseAmount) as PairRankType,
          },
          traits: [],
        };
      },
    }));

    const handlePairRankSelected = useCallback(async (option: Option) => {
      setPairRankOption(option);
    }, []);

    const handleSelectSubject = useCallback((subjectOption: Option) => {
      setSubject(subjectOption.value as TraitSubject);
    }, []);

    return (
      <div className="message-options">
        <CFTitledComponent title="Pair rank type">
          <CFSelect
            testId="select-pair-rank-type"
            onSelected={handlePairRankSelected}
            options={[
              { label: PairRankTypeLabels[PairRankType.PurchaseAmount], value: PairRankType.PurchaseAmount },
              { label: PairRankTypeLabels[PairRankType.PurchaseCount], value: PairRankType.PurchaseCount },
              { label: PairRankTypeLabels[PairRankType.PurchaseQuantity], value: PairRankType.PurchaseQuantity },
              { label: PairRankTypeLabels[PairRankType.Random], value: PairRankType.Random },
            ]}
            value={pairRankOption}
          />
        </CFTitledComponent>

        <CFTitledComponent title="Subject">
          <CFSelect
            value={{
              label: subject,
              value: subject,
            }}
            options={availableSubjects?.map((subject) => ({ label: subject, value: subject })) || []}
            onSelected={handleSelectSubject}
          />
        </CFTitledComponent>
      </div>
    );
  }
);

export default ItemPairConfiguration;
