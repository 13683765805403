import React, { createContext, useCallback, useContext, useRef, useState } from 'react';
import TraitService from 'services/traits/traitSession.service';
import analyticRepository, { init as initAnalyticsRepository } from 'repositories/analytic';
import { init as initApiKeysRepository } from 'repositories/apikeys';
import interventionRepository, { init as initInterventionRepository } from 'services/intervention/intervention.repo';
import { init as initMonitoringRepository } from 'repositories/monitoring';
import { init as initIngestHistoryRepository } from 'repositories/ingesthistory';
import { init as initCatalogHistoryRepository } from 'repositories/cataloghistory';
import { init as initExceptionViewRepository } from 'repositories/exceptionview';

import { init as initMarkovRepository } from 'services/markov/markov.repo';
import { init as initSyncRepository } from 'services/sync/sync.repo';

import nudgeRepository, { init as initNudgesRepository } from 'services/nudging/nudges.repo';

import cohortRepository, { init as initCohortsRepository } from 'services/cohort/cohort.repo';
import { init as initLandingCohortRepository } from 'services/cohort/cohort.repo.landing';
import { init as initOrganizationsRepository } from 'repositories/organizations';
import { init as initProjectRepository } from 'repositories/projects';
import { init as initUserRepository } from 'services/admin/users/users.repo';
import { init as initInternalAdminRepository } from 'services/admin/internal.repo';
import { init as initSchedulingRepo } from 'services/scheduling/scheduling.repo';
import { init as initUploadRepo } from 'services/upload/upload.repo';

import assistantRepository, { init as initAssistantRepository } from 'services/assistant/assistant.repo';

import interventionDraftRepository, { init as initIntvDraftRepository } from 'repositories/interventionDraft';

import adoptedNudgeRepository, { init as initAdoptedNudgeRepository } from 'repositories/adopted_nudges';
import modelRepository, { init as initModelRepository } from 'services/model/model.repo';
import { init as initCensoringRepository } from 'services/model/censoring.repo';

import { init as initModelArmsRepository } from 'services/model/model.arms.repo';

import traitRepository, { init as initTraitsRepository } from 'services/traits/traits.repo';

import { RepoConfig } from 'repositories/types';
import InterventionService from 'services/intervention/intervention.service';
import NudgeService from 'services/nudging/nudge.service';
import ModelService from 'services/model/model.service';
import CohortService from 'services/cohort/cohort.service';
import BackfillService from 'services/traits/backfill.service';

import sessionService from 'services/session/session.service';

import authService from 'services/authorization.service';
import UserEngagementService from 'views/analytics/userEngagement/services';
import AssistantService from 'services/assistant/assistant.service';
import LandingService from 'services/landing/landing.service';
import SchedulingService from 'services/scheduling/scheduling.service';

interface ServicesContextValue {
  init: (params: RepoConfig) => Promise<void>;
  ready: boolean;

  assistantService: AssistantService;
  landingService: LandingService;
  cohortService: CohortService;
  interventionService: InterventionService;
  modelService: ModelService;
  nudgeService: NudgeService;
  traitSessionService: TraitService;
  userEngagementService: UserEngagementService;
  backfillService: BackfillService;
  schedulingService: SchedulingService;
}

const ServicesContext = createContext<ServicesContextValue | undefined>(undefined);

interface Props extends React.PropsWithChildren {}

const ContextProvider: React.FC<Props> = ({ children }) => {
  const [ready, setReady] = useState(false);

  const assistantServiceRef = useRef<AssistantService>();
  const cohortServiceRef = useRef<CohortService>();
  const interventionServiceRef = useRef<InterventionService>();
  const modelServiceRef = useRef<ModelService>();
  const nudgeServiceRef = useRef<NudgeService>();
  const traitSessionServiceRef = useRef<TraitService>();
  const userEngagementServiceRef = useRef<UserEngagementService>();
  const landingServiceRef = useRef<LandingService>();
  const backfillServiceRef = useRef<BackfillService>();
  const schedulingServiceRef = useRef<SchedulingService>();

  const init = useCallback(async (params: RepoConfig) => {
    initAnalyticsRepository(params);
    initOrganizationsRepository(params);
    initProjectRepository(params);
    initUserRepository(params);
    initApiKeysRepository(params);
    initMonitoringRepository(params);
    initIngestHistoryRepository(params);
    initCatalogHistoryRepository(params);
    initExceptionViewRepository(params);
    initTraitsRepository(params);
    initCohortsRepository(params);
    initLandingCohortRepository(params);
    initInterventionRepository(params);
    initNudgesRepository(params);
    initAdoptedNudgeRepository(params);
    initModelRepository(params);
    initCensoringRepository(params);
    initIntvDraftRepository(params);
    initAssistantRepository(params);
    initModelArmsRepository(params);
    initMarkovRepository(params);
    initSyncRepository(params);
    initSchedulingRepo(params);
    initUploadRepo(params);

    initInternalAdminRepository(params);

    assistantServiceRef.current = new AssistantService(assistantRepository);

    traitSessionServiceRef.current = new TraitService(traitRepository, sessionService);
    userEngagementServiceRef.current = new UserEngagementService(traitSessionServiceRef.current);

    nudgeServiceRef.current = new NudgeService(
      nudgeRepository,
      adoptedNudgeRepository,
      traitSessionServiceRef.current,
      sessionService
    );
    cohortServiceRef.current = new CohortService(cohortRepository, traitSessionServiceRef.current);
    modelServiceRef.current = new ModelService(modelRepository, traitRepository, traitSessionServiceRef.current);
    backfillServiceRef.current = new BackfillService(traitRepository, cohortRepository);
    landingServiceRef.current = new LandingService(
      traitRepository,
      analyticRepository,
      cohortRepository,
      sessionService
    );

    interventionServiceRef.current = new InterventionService(
      interventionRepository,
      interventionDraftRepository,
      authService,
      cohortServiceRef.current
    );

    schedulingServiceRef.current = new SchedulingService(
      interventionServiceRef.current,
      modelServiceRef.current,
      nudgeServiceRef.current
    );

    backfillServiceRef.current.init();
    modelServiceRef.current.init();

    await traitSessionServiceRef.current.init();

    userEngagementServiceRef.current.init(); // do not wait

    setReady(true);
  }, []);

  return (
    <ServicesContext.Provider
      value={{
        init,
        ready,
        assistantService: assistantServiceRef.current as AssistantService,
        traitSessionService: traitSessionServiceRef.current as TraitService,
        userEngagementService: userEngagementServiceRef.current as UserEngagementService,
        interventionService: interventionServiceRef.current as InterventionService,
        nudgeService: nudgeServiceRef.current as NudgeService,
        cohortService: cohortServiceRef.current as CohortService,
        modelService: modelServiceRef.current as ModelService,
        landingService: landingServiceRef.current as LandingService,
        backfillService: backfillServiceRef.current as BackfillService,
        schedulingService: schedulingServiceRef.current as SchedulingService,
      }}
    >
      {children}
    </ServicesContext.Provider>
  );
};

export const useServicesContext = (): ServicesContextValue => {
  const context = useContext(ServicesContext);
  if (!context) {
    throw new Error('useServicesContext must be used within a ServicesContextProvider');
  }
  return context;
};

export default ContextProvider;
