import { isNull } from 'lodash';
import { useState } from 'react';

interface Props<T> {
  isMulti?: boolean;
  defaultSelected?: T[];
}

function useMultiSelect<T>({ isMulti = true, defaultSelected = [] }: Props<T>) {
  const [selectedItems, setSelectedItems] = useState<T[]>(defaultSelected);

  const setItems = (value: T | null) => {
    if (isNull(value)) {
      setSelectedItems([]);
      return;
    }

    if (!isMulti) {
      setSelectedItems([value]);
      return;
    }

    setSelectedItems((items) => {
      if (items.includes(value as T)) {
        return items.filter((item) => item !== value);
      }
      const newItems = [...items, value as T];
      return Array.from(new Set(newItems));
    });
  };

  return { selectedItems, setItems };
}

export default useMultiSelect;
