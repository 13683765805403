import React, { useState } from 'react';
import { useToast } from 'hooks';
import { ToastType } from 'components/CFToast/types';
import useCFNavigation from 'hooks/useCFNavigation';

import { CFRole } from 'domain/general.types';

import CFInput, { CFInputType } from 'components/CFInput';
import CFButton from 'components/buttons/CFButton';
import CFTitledComponent from 'components/CFTitledComponent';
import CFTitledSection from 'components/CFTitledSection';

import { updatePassword } from 'services/admin/users/users.repo';

import './settings.scss';

const MIN_PASSWORD_LENGTH = 8;
const Settings = () => {
  const { addToast } = useToast();
  const navigate = useCFNavigation();
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsLoading(true);
    const password = (e.target as HTMLFormElement).password.value;

    if (password.length < MIN_PASSWORD_LENGTH) {
      addToast(`Password must be at least ${MIN_PASSWORD_LENGTH} characters long`, ToastType.WARNING);
      setIsLoading(false);
      return;
    }

    try {
      await updatePassword(password);
      addToast('Password updated successfully', ToastType.SUCCESS);
      navigate('/');
    } catch (error) {
      addToast(`Failed to update password: ${(error as any).message}`, ToastType.ERROR);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="settings">
      <CFTitledSection title="Profile settings">
        <form onSubmit={handleSubmit}>
          <CFTitledComponent title="New password">
            <CFInput name="password" type={CFInputType.Password} />
          </CFTitledComponent>

          <CFButton value={'Save'} role={CFRole.Primary} onClick={() => ({})} isLoading={isLoading} />
        </form>
      </CFTitledSection>
    </div>
  );
};

export default Settings;
