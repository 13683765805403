import React, { useCallback, useRef, useState } from 'react';
import dayjs from 'dayjs';
import { faTrashCan } from '@fortawesome/free-solid-svg-icons';

import { useServicesContext } from 'hooks/useServicesContext';
import { useToast } from 'hooks';

import { CFRole } from 'domain/general.types';

import { Message, Nudge, NudgeMessageDefinition, NudgeType } from 'services/nudging/nudge.types';
import { templateToJSX } from 'services/nudging/templateParsing';
import { edit } from 'services/nudging/nudges.repo';
import { AuthAction } from 'services/authorization.service';

import KeyValue from 'views/intervention/components/KeyValue';

import NudgeMessageForm, { NudgeMessageRef } from 'connected-components/nudges/NudgeMessageForm';
import ProtectedElement from 'connected-components/ProtectedElement';

import CFEditButton from 'components/buttons/CFEditButton';
import CFModal from 'components/CFModal';
import CFButton from 'components/buttons/CFButton';
import { ToastType } from 'components/CFToast/types';
import CFConfirmableButton from 'components/CFConfirmableButton';

import './nudge-common.scss';
import './nudge-detail.scss';

interface Props {
  nudge: Nudge;
  removable?: boolean;
  onDelete?: () => void;
}

const NudgeDetail = ({ nudge: { definition, created_at, created_by, id }, removable = false, onDelete }: Props) => {
  const { addToast } = useToast();
  const nudgeMessageRef = useRef<NudgeMessageRef>() as React.MutableRefObject<NudgeMessageRef>;
  const { nudgeService } = useServicesContext();

  const [editing, setEditing] = useState(false);
  const [liveDefinition, setLiveDefinition] = useState(definition);

  if (!liveDefinition) {
    return <div></div>;
  }

  const handleRemoveNudge = async (nudgeId: number) => {
    try {
      await nudgeService.remove(nudgeId);
      onDelete?.();
    } catch (err) {
      addToast(`Error removing nudge: ${(err as any).message}`, ToastType.ERROR);
    }
  };

  const handleEdit = useCallback(() => {
    setEditing(true);
  }, []);

  const handleSaveEdit = useCallback(async () => {
    let prevValue;

    try {
      prevValue = liveDefinition;
      const newValue = nudgeMessageRef.current.value().message;

      setEditing(false);

      setLiveDefinition((definition) => ({ ...definition, message: newValue as Message }));

      await edit(id, newValue);
    } catch (err) {
      addToast(`Error editing nudge: ${(err as any).message}`, ToastType.ERROR);
      setLiveDefinition(prevValue as NudgeMessageDefinition);
    }
  }, [nudgeMessageRef, liveDefinition]);

  return (
    <>
      {editing && (
        <CFModal onClose={() => setEditing(false)} className="nudge-detail-editor">
          <NudgeMessageForm
            ref={nudgeMessageRef}
            defaultMessage={liveDefinition.type === NudgeType.Message ? liveDefinition.message : undefined}
            onMessageChange={() => {
              /**/
            }}
            onTitleChange={() => {
              /**/
            }}
          />

          <div className="nudge-detail-editor__controls">
            <CFButton role={CFRole.Secondary} value={'Cancel'} onClick={() => setEditing(false)} />
            <CFButton role={CFRole.Primary} value={'Update'} onClick={handleSaveEdit} />
          </div>
        </CFModal>
      )}

      <div className="nudge-detail">
        <div className="nudge-detail__controls">
          <ProtectedElement authAction={AuthAction.CreateNudge}>
            {liveDefinition.type === NudgeType.Message && <CFEditButton onClick={handleEdit} />}

            {removable && (
              <CFConfirmableButton title={'Remove nudge'} question={'Are you sure to remove this nudge?'}>
                <CFButton value="Delete nudge" iconName={faTrashCan} onClick={() => handleRemoveNudge(id)} />
              </CFConfirmableButton>
            )}
          </ProtectedElement>
        </div>

        <div className="nudge-detail__contain">
          {liveDefinition.type === NudgeType.Message && (
            <div className="col">
              <KeyValue name="Title" value={liveDefinition.message.title} />
              <KeyValue name="Message" value={<>{templateToJSX(liveDefinition.message.body)}</>} forceBreakline />
              {liveDefinition.message.tmpl_cfg && (
                <KeyValue name="Subject type" value={liveDefinition.message.tmpl_cfg.item_pair_cfg.item_type} />
              )}
            </div>
          )}
          {liveDefinition.type === NudgeType.Recommender && (
            <div className="col">
              <KeyValue name="Model ID" value={liveDefinition.recommend.model_id} />
              <KeyValue name="Subject type" value={liveDefinition.recommend.subject_type} />
            </div>
          )}
          <div className="col">
            <KeyValue name="Created at" value={dayjs(created_at).format('YYYY-MM-DD')} />
            <KeyValue name="Created by" value={created_by || ''} />
          </div>
        </div>
      </div>
    </>
  );
};

export default NudgeDetail;
