import React from 'react';
import microsoftLogo from '../../assets/images/microsoft_logo.svg';

import './microsoft-login.scss';

interface Props {
  onClick: () => void;
}

const MicrosoftLoginButton = ({ onClick }: Props) => {
  return (
    <div>
      <button className="microsoft-login-button" onClick={onClick}>
        <img src={microsoftLogo} alt="Microsoft logo" />
        Sign in with Microsoft
      </button>
    </div>
  );
};

export default MicrosoftLoginButton;
