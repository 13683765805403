import React, { useState } from 'react';

import { Transformation } from 'domain/model.types';
import { Trait } from 'domain/traits.types';

import { Ptr } from 'services/cohort/cohort.types.api';
import { createTraitCode, getDisplayName } from 'services/traits/helpers.traits';

import CFTraitItem from 'connected-components/traits/CFTraitItem';

import CFDataTable from 'components/CFDataTable';
import { Column, ColumnType } from 'components/CFTable';
import SearchField from 'components/CFSelect/defaults/SearchField';
import CFCheckbox from 'components/CFCheckbox';
import CFInput from 'components/CFInput';
import CFSelect from 'components/CFSelect';

import './traits-table.scss';

interface Props {
  actionSection?: JSX.Element;
  traits: Trait[];
  allowToSelectAll?: boolean;
  allowSingleSelection?: boolean;
  //header: Column[];
  selected: Trait[];
  showWindow?: boolean;
  windowTitle?: string;
  showTransformation?: boolean;
  defaultTransformations?: Record<Ptr, Transformation>;
  handleSelectTrait: (
    trait: Trait,
    selected: boolean,
    windows: number[],
    transformation: Transformation | undefined
  ) => void;
  onSelectAll?: (selected: boolean) => void;
}

const windowsStringToArray = (str: string) => {
  if (!str) {
    return [];
  }

  return str
    .split(',')
    .map((value) => value.trim())
    .filter((value) => value.length !== 0)
    .map((value) => parseFloat(value));
};

const TraitsTable = ({
  traits,
  selected,
  allowToSelectAll = false,
  allowSingleSelection = true,
  showWindow = false,
  windowTitle = 'Windows',
  showTransformation = false,
  handleSelectTrait,
  onSelectAll,
  actionSection,
  defaultTransformations = {},
}: Props): JSX.Element => {
  const [page, setPage] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(10);
  const [searchString, setSearchString] = useState('');
  const [selectedAll, setSelectedAll] = useState(selected.length === traits.length);

  const [windows, setWindows] = useState<Record<Ptr, string>>({});
  const [transformations, setTransformations] = useState<Record<Ptr, Transformation>>(defaultTransformations);

  const filteredTraits = traits.filter((option) => {
    return !searchString.trim() || getDisplayName(option).toLowerCase().includes(searchString.trim().toLowerCase());
  });

  const tableData = filteredTraits
    .slice(page * pageSize, (page + 1) * pageSize)
    .map((trait) => ({ id: createTraitCode(trait), ...trait }));

  const handleSearch = (value: string) => {
    setSearchString(value);
    setPage(0);
  };

  const onPaginated = (page: number, size: number) => {
    setPage(page - 1);
    setPageSize(size);
  };

  const handleSelectAll = (selected: boolean) => {
    if (!onSelectAll) {
      return;
    }

    setSelectedAll(selected);

    onSelectAll(selected);
  };

  const updateTransformation = (trait: Trait, value: string) => {
    setTransformations((transformations) => ({ ...transformations, [trait.addr.ptr]: value as Transformation }));
    handleSelectTrait(trait, true, windowsStringToArray(windows[trait.addr.ptr]), value as Transformation);
  };

  const updateInputValue = (trait: Trait, value: string) => {
    const windowValues = windowsStringToArray(value);

    if (windowValues.find((item) => Number.isNaN(item)) !== undefined) {
      return;
    }

    setWindows((windows) => ({ ...windows, [trait.addr.ptr]: value }));

    handleSelectTrait(trait, true, windowValues, transformations[trait.addr.ptr]);
  };

  let headers: Column[] = [
    {
      title: '',
      field: 'id',
      type: ColumnType.NUMBER,
      style: { width: '30px' },
      renderCell: (row) => {
        if (allowSingleSelection) {
          return (
            <CFCheckbox
              name={`checkbox-${(row as Trait).addr.ptr}`}
              checked={selected.some((trait) => createTraitCode(trait) === createTraitCode(row as Trait))}
              onChange={(selected: boolean) =>
                handleSelectTrait(
                  row as Trait,
                  selected,
                  windowsStringToArray(windows[row.addr.ptr]),
                  transformations[row.addr.ptr]
                )
              }
            />
          );
        }
      },
      renderHeader: () => allowToSelectAll && <CFCheckbox checked={selectedAll} onChange={handleSelectAll} />,
    },
    {
      title: '',
      field: '',
      style: { minWidth: '200px' },
      type: ColumnType.STRING,
      renderCell: (row) => {
        return <CFTraitItem addr={row.addr} showAggLevel={true} omitDisplayName={true} />;
      },
    },
    {
      title: 'Title',
      field: 'display_name',
      style: { minWidth: '200px' },
      type: ColumnType.STRING,
      renderCell: (row) => {
        return getDisplayName(row as Trait);
      },
    },
    {
      title: 'Description',
      field: 'meta.description',
      type: ColumnType.STRING,
      style: {
        width: '100%',
      },
    },
  ];

  if (showWindow) {
    headers = [
      ...headers,
      {
        title: windowTitle,
        field: '',
        type: ColumnType.STRING,
        style: {
          minWidth: '200px',
        },
        renderCell: (row) => {
          return (
            <div>
              <CFInput
                name={`window-${row.addr.ptr}`}
                onChange={(evt) => updateInputValue(row as Trait, evt.currentTarget.value)}
                defaultValue={windows[row.addr.ptr]}
              />
            </div>
          );
        },
      },
    ];
  }

  if (showTransformation) {
    headers = [
      ...headers,
      {
        title: 'Transformation',
        field: '',
        type: ColumnType.STRING,
        style: {
          minWidth: '200px',
        },
        renderCell: (row) => {
          return (
            <div>
              <CFSelect
                options={[
                  { label: Transformation.PPMI.toUpperCase(), value: Transformation.PPMI },
                  { label: Transformation.LOG1P.toUpperCase(), value: Transformation.LOG1P },
                ]}
                isMulti={false}
                onSelected={(evt) => updateTransformation(row as Trait, evt.value)}
                value={{
                  label: transformations[(row as Trait).addr.ptr]?.toUpperCase(),
                  value: transformations[(row as Trait).addr.ptr],
                }}
              />
            </div>
          );
        },
      },
    ];
  }

  return (
    <div className="traits-table">
      <div className="actions">
        {actionSection}
        <div className="search">
          <SearchField autoFocus={false} onChange={handleSearch} />
        </div>
      </div>
      <form>
        <CFDataTable
          key={searchString}
          total={filteredTraits.length}
          headers={headers}
          data={tableData}
          onPaginated={onPaginated}
          selected={selected}
        />
      </form>
    </div>
  );
};

export default TraitsTable;
