import { AxiosResponse } from 'axios';
import {
  get as httpGet,
  post as httpPost,
  remove as httpDelete,
  put as httpPut,
} from '../../repositories/drivers/http';

import {
  CohortAPI as CohortAPI,
  CohortID,
  CohortType,
  InternalCohort,
  SampleInfo,
  SampleItem,
  SamplePurpose,
} from 'services/cohort/cohort.types';
import { PaginatedElement } from 'types';
import { RepoConfig } from '../../repositories/types';
import { ColAddr, TraitSubject } from 'domain/traits.types';
import { GenerateSampleRequestAPI, SampleResponse } from 'services/cohort/cohort.types';
import Cohort from './domain/Cohort';
import FilterSet from './domain/FilterSet';
import Filter from './domain/Filter';

const serverBaseUrl = process.env.REACT_APP_SERVER_BASE_URL;
const path = '/v1/cohort';

const repoConfig = {
  token: '',
  oid: -1,
  pid: -1,
};

export interface CFCohortRepository {
  init: ({ token, oid, pid }: RepoConfig) => void;
  backfillCohort: (cohortId: CohortID) => Promise<void>;
  create: (cohort: InternalCohort) => void;
  get: (page: number, per_page: number) => Promise<PaginatedElement<Cohort>>;
  getById: (id: CohortID) => Promise<Cohort>;
  getCohortListBySubject: (
    page: number,
    per_page: number,
    subject_type: TraitSubject,
    modules: string[],
    cohort_type?: CohortType
  ) => Promise<PaginatedElement<Cohort>>;

  generateStaticSample: (
    cohort: Cohort,
    extraFilter: Filter[],
    sampleSize: number,
    sampleId: string | undefined,
    purpose: SamplePurpose
  ) => Promise<SampleResponse>;
  getTraitBasicStats: (sampleId: string, addr: ColAddr, group?: string) => Promise<any>;
  getTraitBasicStatsForCohort: (cohortId: string, addr: ColAddr) => Promise<any>;
  getSampleInfo: (sampleId: string) => Promise<SampleInfo>;
  preview: (filterSet: FilterSet, page: number, per_page: number) => Promise<PaginatedElement<string>>;
  remove: (cohortId: number) => void;
  subject: (cohortId: number, page: number, per_page: number) => Promise<PaginatedElement<any>>;
  sample: (sampleId: string, page: number, per_page: number) => Promise<PaginatedElement<SampleItem>>;
}

export const init = ({ token, oid, pid }: RepoConfig) => {
  repoConfig.token = token;
  repoConfig.oid = oid;
  repoConfig.pid = pid;
};

export const get = async (page: number, per_page: number): Promise<PaginatedElement<Cohort>> => {
  const config = {
    params: {
      pid: repoConfig.pid,
      oid: repoConfig.oid,
      page,
      per_page,
    },
  };

  try {
    const {
      data: { total, data },
    } = (await httpGet(`${serverBaseUrl}${path}/list`, config)) as AxiosResponse<PaginatedElement<CohortAPI>>;

    return { total, data: (data || []).map((cohort) => new Cohort(cohort)) || [] };
  } catch (err) {
    console.error('error listing cohorts: ', err);
    throw new Error('error-listing-cohorts');
  }
};

export const getById = async (id: CohortID): Promise<Cohort> => {
  const config = {
    params: {
      pid: repoConfig.pid,
      oid: repoConfig.oid,
    },
  };

  try {
    const cohortResponse = (await httpGet(`${serverBaseUrl}${path}/get/${id}`, config)) as AxiosResponse<CohortAPI>;

    return new Cohort(cohortResponse.data);
  } catch (err) {
    console.error('error getting cohort: ', err);
    throw new Error('error-getting-cohort');
  }
};

export const updateLandingCohortOLD = async (cohortId: string): Promise<void> => {
  const config = {
    params: {
      pid: repoConfig.pid,
      oid: repoConfig.oid,
    },
  };

  try {
    await httpPut(`${serverBaseUrl}${path}/set-landing/${cohortId}`, {}, config);
  } catch (err) {
    console.error('error setting landing cohort: ', err);
    throw new Error('error-setting-landing-cohort-analytics');
  }
};

export const getCohortListBySubject = async (
  page: number,
  per_page: number,
  subject_type: TraitSubject,
  modules: string[],
  cohort_type?: CohortType
): Promise<PaginatedElement<Cohort>> => {
  const config = {
    params: {
      pid: repoConfig.pid,
      oid: repoConfig.oid,
      page,
      per_page,
      subject_type,
      module: modules,
      cohort_type: cohort_type || CohortType.All,
    },
  };

  try {
    const {
      data: { total, data },
    } = (await httpGet(`${serverBaseUrl}${path}/list`, config)) as AxiosResponse<PaginatedElement<CohortAPI>>;

    return { total, data: (data || []).map((cohort) => new Cohort(cohort)) || [] };
  } catch (err) {
    console.error('error listing cohorts: ', err);
    throw new Error('error-listing-cohorts');
  }
};

export const create = async (cohort: InternalCohort) => {
  const config = {};

  const body = {
    ...cohort,
    tree: cohort.tree.getLegacy(),
  };

  try {
    await httpPost(`${serverBaseUrl}${path}/create?oid=${repoConfig.oid}&pid=${repoConfig.pid}`, body, config);
  } catch (err) {
    throw new Error((err as any).response.data.message);
  }
};

export const backfillCohort = async (cohortId: CohortID) => {
  const config = {
    params: {
      oid: repoConfig.oid,
      pid: repoConfig.pid,
    },
  };

  const body = {};

  (await httpPut(`${serverBaseUrl}${path}/backfill/${cohortId}`, body, config)) as AxiosResponse;
};

export const preview = async (
  filterSet: FilterSet,
  page: number,
  per_page: number
): Promise<PaginatedElement<string>> => {
  const config = {
    params: {
      pid: repoConfig.pid,
      oid: repoConfig.oid,
      page,
      per_page,
    },
  };

  const body = filterSet.getLegacy();

  try {
    const {
      data: { total, data },
    } = (await httpPost(`${serverBaseUrl}${path}/preview-subject`, body, config)) as AxiosResponse;

    return { total, data: data || [] };
  } catch (err) {
    console.error('error on preview subject: ', err);
    throw new Error('error-preview-subject');
  }
};

export const subject = async (cohortId: number, page: number, per_page: number): Promise<PaginatedElement<any>> => {
  const config = {
    params: {
      pid: repoConfig.pid,
      oid: repoConfig.oid,
      page,
      per_page,
    },
  };

  try {
    const {
      data: { total, data },
    } = (await httpGet(`${serverBaseUrl}${path}/subject/${cohortId}`, config)) as AxiosResponse;

    return { total, data: data || [] };
  } catch (err) {
    console.error('error getitngs subjects: ', err);
    throw new Error('error-cohort-subject');
  }
};

export const sample = async (
  sampleId: string,
  page: number,
  per_page: number
): Promise<PaginatedElement<SampleItem>> => {
  const config = {
    params: {
      pid: repoConfig.pid,
      oid: repoConfig.oid,
      page,
      per_page,
    },
  };

  try {
    const {
      data: { total, data },
    } = (await httpGet(`${serverBaseUrl}${path}/sample/subject/${sampleId}`, config)) as AxiosResponse;

    return { total, data: data || [] };
  } catch (err) {
    console.error('error getting cohort sample: ', err);
    throw new Error('error-cohort-sample');
  }
};

export const generateStaticSample = async (
  cohort: Cohort,
  extraFilter: Filter[],
  sampleSize: number,
  sampleId: string | undefined,
  purpose: SamplePurpose
): Promise<SampleResponse> => {
  const config = {};

  const sampleRequest: GenerateSampleRequestAPI = {
    ...(sampleId ? { id: sampleId } : {}),
    cohort_id: cohort.id,
    extra_filter:
      extraFilter.map((filter) => ({
        op: filter.op,
        ptr: filter.ptr,
        val: filter.val,
      })) || [],
    count: sampleSize,
    subject_type: cohort.subject_type,
    purpose,
  };

  try {
    const data = (await httpPost(
      `${serverBaseUrl}${path}/sample/generate?oid=${repoConfig.oid}&pid=${repoConfig.pid}`,
      sampleRequest,
      config
    )) as AxiosResponse;
    return data.data;
  } catch (err) {
    console.error('error getting cohort sample: ', err);
    throw new Error('error-cohort-sample');
  }
};

export const remove = async (cohortId: number) => {
  const config = {
    params: {
      pid: repoConfig.pid,
      oid: repoConfig.oid,
    },
  };

  try {
    (await httpDelete(`${serverBaseUrl}${path}/delete/${cohortId}`, config)) as AxiosResponse;
  } catch (err) {
    console.error('error remove cohort: ', err);
    throw new Error('error-removing-cohort');
  }
};

export const getTraitBasicStats = async (sampleId: string, addr: ColAddr, group?: string) => {
  const config = {};

  const body = addr;

  let queryString = `?oid=${repoConfig.oid}&pid=${repoConfig.pid}`;

  if (group) {
    queryString += `&group=${group}`;
  }

  try {
    const data = await httpPost(`${serverBaseUrl}${path}/sample/basic-stats/${sampleId}${queryString}`, body, config);

    return data?.data;
  } catch (err) {
    throw new Error((err as any).response.data.message);
  }
};

export const getTraitBasicStatsForCohort = async (cohortId: string, addr: ColAddr) => {
  const config = {};

  const body = addr;
  const queryString = `?oid=${repoConfig.oid}&pid=${repoConfig.pid}`;

  try {
    const data = await httpPost(`${serverBaseUrl}${path}/basic-stats/${cohortId}${queryString}`, body, config);

    return data?.data;
  } catch (err) {
    throw new Error((err as any).response.data.message);
  }
};

export const getSampleInfo = async (sampleId: string): Promise<SampleInfo> => {
  const config = {};

  try {
    const data = await httpGet(
      `${serverBaseUrl}${path}/sample/info/${sampleId}?oid=${repoConfig.oid}&pid=${repoConfig.pid}`,
      config
    );

    return data?.data;
  } catch (err) {
    throw new Error((err as any).response.data.message);
  }
};

export default {
  init,
  get,
  getById,
  getCohortListBySubject,
  create,
  preview,
  subject,
  sample,
  generateStaticSample,
  remove,
  getTraitBasicStats,
  getTraitBasicStatsForCohort,
  getSampleInfo,
  backfillCohort,
};
