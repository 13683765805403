import React, { useMemo } from 'react';

import { Trait } from 'domain/traits.types';

import CFTraitItem from 'connected-components/traits/CFTraitItem';

import CFTable, { Column, ColumnType } from 'components/CFTable';

import './metrics-section.scss';
import { Ptr } from 'services/cohort/cohort.types.api';

interface Props {
  traits: Trait[];
  title: string;
  extendedInfo?: string[] | number[];
}

interface Row {
  ptr: Ptr;
  type: string;
  description: string;
  extended: string | number;
}

const MetricsSection = ({ traits, title, extendedInfo }: Props) => {
  const isExtendedValid = useMemo(() => {
    return extendedInfo?.length === traits.length;
  }, []);

  const columns: Column[] = useMemo(() => {
    let fields = [
      {
        title: 'Trait',
        field: 'ptr',
        type: ColumnType.STRING,
        renderCell: (row: any) => <CFTraitItem addr={(row as Row).ptr} showAggLevel={true} />,
        style: { width: '450px' },
      },
      {
        title: 'Description',
        field: 'description',
        type: ColumnType.STRING,
      },
    ];

    if (isExtendedValid) {
      const [first, last] = fields;

      fields = [
        first,
        {
          title: 'Weight',
          field: 'extended',
          type: ColumnType.STRING,
        },
        last,
      ];
    }

    return fields;
  }, [isExtendedValid]);

  const data: Row[] = useMemo(() => {
    return traits.map((trait, i) => ({
      ptr: trait.addr.ptr,
      type: trait.addr.dtype,
      description: trait.meta.description,
      extended: extendedInfo?.[i] || '',
    }));
  }, [traits, extendedInfo]);

  return (
    <div className="metrics-section">
      <div className="metrics-section__title">{title}</div>

      <CFTable headers={columns} data={data} skipHeader={extendedInfo === undefined} />
    </div>
  );
};

export default MetricsSection;
