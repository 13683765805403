import React, { useCallback, useRef, useState } from 'react';

import useCFNavigation from 'hooks/useCFNavigation';
import useToast from 'hooks/useToast';
import { useServicesContext } from 'hooks/useServicesContext';

import { CFRole } from 'domain/general.types';

import { allowedTabs, interventionByTab, Tabs } from 'views/intervention';

import NudgeMessageForm, { NudgeMessageRef } from 'connected-components/nudges/NudgeMessageForm';

import {
  CallToAction,
  CreatingNudge,
  Message,
  NudgeDefinition,
  NudgeType,
  RenderMethod,
  RenderPage,
} from 'services/nudging/nudge.types';
import NudgeService from 'services/nudging/nudge.service';

import CFTitledComponent, { TitleSize } from 'components/CFTitledComponent';
import CFButton from 'components/buttons/CFButton';
import { CFNavList } from 'components/CFNavList';
import { ToastType } from 'components/CFToast/types';

import NudgeConfigForm, { NudgeConfigRef } from './NudgeConfigForm';
import IOSNudgePreview from './IOSNudgePreview';
import AndroidNudgePreview from './AndroidNudgePreview';
import NudgeRecommendForm, { NudgeRecommentRef } from './NudgeRecommendForm';

import { CFRoutes } from 'routes';

import pagePlusIcon from 'assets/icons/pagePlus.svg';

import './new-nudge.scss';
import '../nudge-common.scss';

interface NewNudgeProps {
  nudgeService: NudgeService;
  handleReady: (nudge: Record<string, any>) => void;
  handleCancel: () => void;
}

export const NewNudgeView = () => {
  const { nudgeService } = useServicesContext();
  const navigate = useCFNavigation();

  const handleNudgeReady = () => {
    navigate(CFRoutes.intervention_nudge_list);
  };

  const handleCancel = () => {
    navigate(CFRoutes.intervention_nudge_list);
  };

  return (
    <div className="new-nudge-container">
      <CFNavList
        titles={allowedTabs()}
        selected={Tabs.Nudges}
        onClick={(selectedTab) => navigate(interventionByTab[selectedTab])}
      />

      <NewNudge nudgeService={nudgeService} handleReady={handleNudgeReady} handleCancel={handleCancel} />
    </div>
  );
};

const NewNudge = ({ nudgeService, handleCancel, handleReady }: NewNudgeProps) => {
  const { addToast } = useToast();

  const nudgeConfigRef = useRef<NudgeConfigRef>() as React.MutableRefObject<NudgeConfigRef>;
  const nudgeMessageRef = useRef<NudgeMessageRef>() as React.MutableRefObject<NudgeMessageRef>;
  const nudgeRecommendRef = useRef<NudgeRecommentRef>() as React.MutableRefObject<NudgeRecommentRef>;

  const [nudgeType, setNudgeType] = useState(NudgeType.Message);

  const [message, setMessage] = useState('');
  const [title, setTitle] = useState('');

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleMessageChange = useCallback((message: string) => {
    setMessage(message);
  }, []);

  const handleTitleChange = useCallback((title: string) => {
    setTitle(title);
  }, []);

  const handleCancelNudgeCreation = useCallback(async () => {
    handleCancel();
  }, []);

  const handleTypeChange = (nudgeType: NudgeType) => {
    setNudgeType(nudgeType);
  };

  const handleNewNudge = useCallback(async () => {
    setIsLoading(true);

    const nudgeConfig = nudgeConfigRef.current.value();

    let nudgeDefinition: NudgeDefinition;

    if (nudgeType === NudgeType.Message) {
      const nudgeMessage = nudgeMessageRef.current.value();

      nudgeDefinition = {
        type: NudgeType.Message,
        message: { ...nudgeMessage.message, tags: nudgeConfig.tags } as Message,
        render_method: nudgeConfig.render_method as RenderMethod,
        cta: CallToAction.Redirect,
        render_page: nudgeConfig.render_page as RenderPage,
        noop: nudgeConfig.noop,
      };
    } else {
      nudgeDefinition = nudgeRecommendRef.current.value();
    }

    const newNudge: CreatingNudge = {
      name: nudgeConfig.name,
      description: nudgeConfig.description,
      definition: nudgeDefinition as NudgeDefinition,
    };

    try {
      // TODO: make nudgeService to return the created ID
      await nudgeService.create(newNudge);
      handleReady(newNudge);
    } catch {
      addToast('Error creating nudge', ToastType.ERROR);
    }

    setIsLoading(false);
  }, [nudgeService, nudgeType]);

  return (
    <div className={`new-nudge new-nudge-${nudgeType}`}>
      <div className="new-nudge__header"> Create Nudge</div>

      <CFTitledComponent title="Nudge details" className="full-height new-nudge__config" size={TitleSize.Extra}>
        <NudgeConfigForm ref={nudgeConfigRef} onTypeChanged={handleTypeChange} nudgeType={nudgeType} />
      </CFTitledComponent>

      {nudgeType === NudgeType.Message && (
        <>
          <CFTitledComponent title="Nudge message" className="full-height new-nudge__message" size={TitleSize.Extra}>
            <NudgeMessageForm
              ref={nudgeMessageRef}
              onMessageChange={handleMessageChange}
              onTitleChange={handleTitleChange}
            />
          </CFTitledComponent>

          <CFTitledComponent title="Nudge preview" className="full-height new-nudge__viewer" size={TitleSize.Extra}>
            <div className="content">
              <IOSNudgePreview text={message} title={title} />
              <AndroidNudgePreview text={message} title={title} />
            </div>
          </CFTitledComponent>
        </>
      )}

      {nudgeType === NudgeType.Recommender && (
        <CFTitledComponent title="Nudge recommend" className="full-height new-nudge__message" size={TitleSize.Extra}>
          <NudgeRecommendForm ref={nudgeRecommendRef} />
        </CFTitledComponent>
      )}

      <div className="new-nudge__controls">
        <CFButton value={'Cancel'} onClick={handleCancelNudgeCreation} role={CFRole.Secondary} />
        <CFButton
          value={'Create Nudge'}
          iconCustom={<img src={pagePlusIcon} />}
          isLoading={isLoading}
          onClick={handleNewNudge}
          role={CFRole.Primary}
        />
      </div>
    </div>
  );
};

export default NewNudge;
