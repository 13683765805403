import { AxiosResponse } from 'axios';
import { get as httpGet, post as httpPost, remove as httpDelete, put as httpPut } from 'repositories/drivers/http';

import { PaginatedElement } from 'types';
import { AlgoMeta, EmbeddingPlots, Model, ModelId, ModelMetrics, NewModelRequest } from 'domain/model.types';
import { AlgorithmClass } from 'services/intervention/intervention.types';
import { RepoConfig } from 'repositories/types';

const serverBaseUrl = process.env.REACT_APP_SERVER_BASE_URL;
const path = '/v1/model';

const repoConfig = {
  token: '',
  oid: -1,
  pid: -1,
};

export interface CFModelRepository {
  init: ({ token, oid, pid }: RepoConfig) => void;
  get: (
    page: number,
    per_page: number,
    type: AlgorithmClass | undefined,
    fetchAll: boolean
  ) => Promise<PaginatedElement<Model>>;
  getInvId: (id: ModelId) => Promise<number>;
  create: (request: NewModelRequest) => void;
  remove: (id: ModelId) => void;
  getClassmeta: () => Promise<AlgoMeta[]>;
  getMetrics: (modelId: ModelId) => Promise<ModelMetrics>;
  getById: (modelId: ModelId) => Promise<Model>;
  pin: (id: string, pinned: boolean) => void;
  addTag: (id: string, tag: string) => void;
  removeTag: (id: string, tag: string) => void;
  pause: (modelId: ModelId, status: boolean) => void;
  publish: (modelId: ModelId, status: boolean) => void;
  getUsers: (modelId: ModelId, page: number, per_page: number) => Promise<PaginatedElement<string>>;
}

export const init = ({ token, oid, pid }: RepoConfig) => {
  repoConfig.token = token;
  repoConfig.oid = oid;
  repoConfig.pid = pid;
};

export const get = async (
  page: number,
  per_page: number,
  type: AlgorithmClass | undefined = undefined,
  fetchAll: boolean
): Promise<PaginatedElement<Model>> => {
  const config = {
    params: {
      pid: repoConfig.pid,
      oid: repoConfig.oid,
      page,
      per_page,
      class_name: type,
      published: fetchAll ? undefined : true,
    },
  };

  try {
    const {
      data: { total, data },
    } = (await httpGet(`${serverBaseUrl}${path}/list`, config)) as AxiosResponse;

    const models = (data || []).map((model: Model) => ({
      ...model,
      definition: {
        ...model.definition,
        tags: model.definition.tags ?? [],
      },
    }));

    return { total, data: models };
  } catch (err: any) {
    throw new Error(err.response.data.message);
  }
};

export const getInvId = async (id: ModelId): Promise<number> => {
  const config = {};

  try {
    const { data: data } = (await httpGet(
      `${serverBaseUrl}${path}/invid/${id}?oid=${repoConfig.oid}&pid=${repoConfig.pid}`,
      config
    )) as AxiosResponse;

    return data;
  } catch (err: any) {
    throw new Error(err.response.data.message);
  }
};

export const create = async (request: NewModelRequest) => {
  const config = {};

  const requestAPI = { ...request };

  requestAPI.definition.population_policy.cohort.extra_filters =
    requestAPI.definition.population_policy.cohort.extra_filters.map((filter) => ({
      op: filter.op,
      ptr: filter.ptr,
      val: filter.val,
    }));

  try {
    const data = (await httpPost(
      `${serverBaseUrl}${path}/create?oid=${repoConfig.oid}&pid=${repoConfig.pid}`,
      requestAPI,
      config
    )) as AxiosResponse;
    return data.data;
  } catch (err: any) {
    throw new Error(err.response.data.message);
  }
};

export const remove = async (id: ModelId) => {
  const config = {
    params: {
      pid: repoConfig.pid,
      oid: repoConfig.oid,
    },
  };

  try {
    (await httpDelete(`${serverBaseUrl}${path}/delete/${id}`, config)) as AxiosResponse;
  } catch (err: any) {
    throw new Error(err.response.data.message);
  }
};

export const getClassmeta = async (): Promise<AlgoMeta[]> => {
  const config = {
    params: {
      pid: repoConfig.pid,
      oid: repoConfig.oid,
    },
  };

  try {
    const { data } = (await httpGet(`${serverBaseUrl}${path}/classmeta`, config)) as AxiosResponse;
    return data;
  } catch (err: any) {
    throw new Error(err.response.data.message);
  }
};

export const getMetrics = async (modelId: ModelId): Promise<ModelMetrics> => {
  const CENSORING_KEY = 'censoring';

  const config = {
    params: {
      pid: repoConfig.pid,
      oid: repoConfig.oid,
    },
  };

  try {
    const { data } = (await httpGet(
      `${serverBaseUrl}${path}/metric/${modelId}`,
      config
    )) as AxiosResponse<ModelMetrics>;

    if (CENSORING_KEY in data) {
      data.censoring.diag.x = data.censoring.diag.x || [];
      data.censoring.diag.y = data.censoring.diag.y || [];

      data.censoring.horz.x = data.censoring.horz.x || [];
      data.censoring.horz.y = data.censoring.horz.y || [];
    }

    return data;
  } catch (err: any) {
    throw new Error(err.response.data.message);
  }
};

export const getById = async (modelId: ModelId): Promise<Model> => {
  const config = {
    params: {
      pid: repoConfig.pid,
      oid: repoConfig.oid,
    },
  };

  try {
    const { data } = (await httpGet(`${serverBaseUrl}${path}/get/${modelId}`, config)) as AxiosResponse;
    return data;
  } catch (err: any) {
    throw new Error(err.response.data.message);
  }
};

export const pin = async (id: string, pinned: boolean) => {
  const config = {};

  try {
    const data = (await httpPut(
      `${serverBaseUrl}${path}/set-pin-to-landing/${id}?&oid=${repoConfig.oid}&pid=${repoConfig.pid}`,
      { pin: pinned },
      config
    )) as AxiosResponse;
    return data.data;
  } catch (err: any) {
    throw new Error(err.response.data.message);
  }
};

export const addTag = async (id: string, tag: string) => {
  const config = {
    params: {
      pid: repoConfig.pid,
      oid: repoConfig.oid,
      tag,
    },
  };
  try {
    const data = (await httpPut(`${serverBaseUrl}${path}/tags/add/${id}`, {}, config)) as AxiosResponse;
    return data.data;
  } catch (err: any) {
    throw new Error(err.response.data.message);
  }
};

export const removeTag = async (id: string, tag: string) => {
  const config = {
    params: {
      pid: repoConfig.pid,
      oid: repoConfig.oid,
      tag,
    },
  };
  try {
    const data = (await httpDelete(`${serverBaseUrl}${path}/tags/delete/${id}`, config)) as AxiosResponse;
    return data.data;
  } catch (err: any) {
    throw new Error(err.response.data.message);
  }
};

// eslint-disable-next-line
export const publish = async (modelId: ModelId, status: boolean) => {
  const config = {
    params: {
      pid: repoConfig.pid,
      oid: repoConfig.oid,
      published: status,
    },
  };

  const body = {};

  try {
    const data = (await httpPut(`${serverBaseUrl}${path}/set-published/${modelId}`, body, config)) as AxiosResponse;
    return data.data;
  } catch (err: any) {
    throw new Error(err.response.data.message);
  }
};

export const pause = async (modelId: ModelId, status: boolean) => {
  const config = {};

  const body = {
    status,
  };

  try {
    const data = (await httpPut(
      `${serverBaseUrl}${path}/pause/${modelId}?oid=${repoConfig.oid}&pid=${repoConfig.pid}`,
      body,
      config
    )) as AxiosResponse;
    return data.data;
  } catch (err: any) {
    throw new Error(err.response.data.message);
  }
};

export const getUsers = async (modelId: ModelId, page: number, per_page: number) => {
  const config = {
    params: {
      pid: repoConfig.pid,
      oid: repoConfig.oid,
      page,
      per_page,
    },
  };

  try {
    const {
      data: { total, data },
    } = (await httpGet(`${serverBaseUrl}${path}/users/${modelId}`, config)) as AxiosResponse;

    return { total, data: data || [] };
  } catch (err: any) {
    throw new Error(err.response.data.message);
  }
};

export const getEmbeddingsPlots = async (modelId: ModelId) => {
  const config = {
    params: {
      pid: repoConfig.pid,
      oid: repoConfig.oid,
    },
  };

  try {
    const data = (await httpGet(
      `${serverBaseUrl}${path}/embedding/plots/${modelId}`,
      config
    )) as AxiosResponse<EmbeddingPlots>;

    return data.data;
  } catch (err: any) {
    throw new Error(err.response.data.message);
  }
};

export default {
  init,
  get,
  getInvId,
  create,
  remove,
  getClassmeta,
  getMetrics,
  getById,
  pin,
  addTag,
  removeTag,
  pause,
  publish,
  getUsers,
  getEmbeddingsPlots,
};
