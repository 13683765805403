import { useReducer } from 'react';
import { useSearchParams } from 'react-router-dom';

import { AggLevel } from 'domain/stats.types';

import { Ptr } from 'services/cohort/cohort.types.api';
import { getAggLevel } from 'services/traits/helpers.traits';

import { moveElementDown, moveElementUp } from 'helpers/arrays';

export enum TraitAction {
  DOWN,
  UP,
  REMOVE,
  ADD,
  LOAD,
  CLEAR,
}

export interface TraitEventData {
  action: TraitAction;
  ptr?: Ptr;
  ptrs?: Ptr[];
  aggLevel?: AggLevel;
}

export interface TraitsState {
  traits: Partial<Record<AggLevel, Ptr[]>>;
  aggLevel: AggLevel;
}

const updateSettingsByAggLevels = (addrs: Ptr[]) => {
  return addrs.reduce((acc, cur) => {
    const aggLevel = getAggLevel(cur);

    if (!acc[aggLevel]) {
      acc[aggLevel] = [];
    }

    acc[aggLevel]?.push(cur);
    return acc;
  }, {} as Partial<Record<AggLevel, Ptr[]>>);
};

const traitReducer = (state: TraitsState, event: TraitEventData) => {
  const newState = { ...state };

  switch (event.action) {
    case TraitAction.CLEAR: {
      const aggLevel = event.aggLevel as AggLevel;

      newState.traits[aggLevel] = [];

      return newState;
    }

    case TraitAction.ADD: {
      const aggLevel = getAggLevel(event.ptr as Ptr);

      if (!newState.traits[aggLevel]) {
        newState.traits[aggLevel] = [];
      }
      newState.traits[aggLevel] = [event.ptr as Ptr, ...(state.traits[aggLevel] as Ptr[])];
      return newState;
    }

    case TraitAction.REMOVE: {
      const aggLevel = getAggLevel(event.ptr as Ptr);

      newState.traits[aggLevel] = (state.traits[aggLevel] as Ptr[]).filter((ptr) => ptr !== event.ptr);

      return newState;
    }

    case TraitAction.DOWN: {
      const position = (state.traits[state.aggLevel] || []).findIndex((code) => code === event.ptr);

      newState.traits[state.aggLevel] = moveElementDown(state.traits[state.aggLevel] as Ptr[], position);

      return newState;
    }

    case TraitAction.UP: {
      const position = (state.traits[state.aggLevel] || []).findIndex((code) => code === event.ptr);

      newState.traits[state.aggLevel] = moveElementUp(state.traits[state.aggLevel] as Ptr[], position);

      return newState;
    }

    case TraitAction.LOAD: {
      if (!event.ptrs) {
        return state;
      }

      newState.traits = updateSettingsByAggLevels(event.ptrs);

      return newState;
    }

    default:
      return state;
  }
};

const useInitializedTraits = (): [TraitsState, React.Dispatch<TraitEventData>] => {
  const [searchParams] = useSearchParams();

  const [traitsState, dispatchTraitAction] = useReducer(traitReducer, {}, () => {
    let defaultCharts: string[] = [];

    if (
      searchParams.get('trait') === '' ||
      searchParams.get('trait') === undefined ||
      searchParams.get('trait') === null
    ) {
      defaultCharts = [];
    } else {
      defaultCharts = searchParams.get('trait')?.split(',') as string[];
    }

    const initialState = {
      traits: {
        [AggLevel.Day]: defaultCharts.filter((trait) => getAggLevel(trait) === AggLevel.Day),
        [AggLevel.Week]: defaultCharts.filter((trait) => getAggLevel(trait) === AggLevel.Week),
        [AggLevel.Month]: defaultCharts.filter((trait) => getAggLevel(trait) === AggLevel.Month),
      },
      aggLevel: AggLevel.Day, // <<--------- inspect from URL
    };

    return initialState;
  });

  return [traitsState, dispatchTraitAction];
};

export default useInitializedTraits;
