import axios, { AxiosResponse } from 'axios';
import { CF_AUTH_TOKEN_NAME, get } from 'repositories/drivers/http';
import { setAuthToken } from 'services/session/session.service';
import { UserDetailedInfo } from './types';

export enum AuthType {
  Bearer,
  Basic,
}

/**
 *
 * Backend API: GET /v1/user/me
 *
 */
export const validate = async (
  serverBaseUrl: string,
  token: string,
  type = AuthType.Bearer
): Promise<UserDetailedInfo> => {
  const authPrefix = type === AuthType.Bearer ? 'Bearer' : 'Basic';

  try {
    const config = {
      headers: {
        Authorization: `${authPrefix} ${token}`,
      },
    };
    const loginData = await axios.get(`${serverBaseUrl}/v1/user/login`, config);

    setAuthToken(loginData.headers[CF_AUTH_TOKEN_NAME] as string);
    return loginData.data;
  } catch (err) {
    console.log('Error validating token');
    throw new Error('authentication-error');
  }
};

export const getProfile = async (serverBaseUrl: string): Promise<UserDetailedInfo> => {
  try {
    const loginData = (await get(`${serverBaseUrl}/v1/user/me`, {})) as AxiosResponse<UserDetailedInfo>;

    return loginData.data;
  } catch (err) {
    console.log('Error getting profile info');
    throw new Error('profile-error');
  }
};
