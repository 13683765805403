import React from 'react';

import CFChip, { CFChipRole, CFChipSize } from 'components/chips/CFChip';

import './cloud-picker.scss';

export interface CloudOption {
  label: string;
  value: string;
  selected: boolean;
}

interface Props {
  tags: CloudOption[];
  onClick?: (value: string) => void;
}

const CloudPicker = ({ onClick, tags }: Props) => {
  return (
    <div className="cloud-picker">
      {tags
        .sort((a, b) => a.label.localeCompare(b.label))
        .map((tag) => (
          <CFChip
            key={tag.value}
            value={tag.label}
            removable={false}
            size={CFChipSize.Small}
            role={tag.selected ? CFChipRole.Primary : CFChipRole.Secondary}
            onSelect={() => onClick?.(tag.value)}
          />
        ))}
    </div>
  );
};

export default CloudPicker;
