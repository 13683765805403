import React, { useCallback, useEffect, useState } from 'react';

import debounce from 'debounce';

import useCFNavigation from 'hooks/useCFNavigation';
import useToast from 'hooks/useToast';
import { useServicesContext } from 'hooks/useServicesContext';

import { CFRole } from 'domain/general.types';

import { AlgorithmSpec, AlgorithmType, NewInterventionRequest } from 'services/intervention/intervention.types';

import { useInterventionContext } from 'views/intervention/useContext';

import SchedulingBuilder from 'connected-components/SchedulingBuilder';

import { ToastType } from 'components/CFToast/types';
import { CFNavList } from 'components/CFNavList';
import CFButton from 'components/buttons/CFButton';
import Wizard from 'components/CFWizard';
import { Steps, StepsDisplayNames } from '.';

import InterventionRestlessMetricsPolicy from './metricsPolicy/metrics-restless';
import InterventionParticipantsRestless from './participants/participants-restless';
import InterventionSection from './interventionSection';
import NewContextBanditIntervention from './new-context-bandit-intervention';
import useSourceIntervention from './hooks/useSourceIntervention';
import NewABIntervention from './new-ab-intervention';
import GroupTraits from './groupTraits';
import InterventionDefinition from './definition';
import InterventionNudgePolicy from './nudgePolicy';
import MarkovStates from './states';
import AdvantageScore from './advantageScore';

import { allowedTabs, interventionByTab, Tabs } from '../..';

import { CFRoutes } from 'routes';

import pagePlusIcon from 'assets/icons/pagePlus.svg';

import './intervention.scss';
import NewContextBanditV2Intervention from './new-context-bandit_v2-intervention';

const NewIntervention = () => {
  const navigate = useCFNavigation();

  const {
    cohortService,
    nudgeService,
    traitSessionService: traitService,
    modelService,
    interventionService,
  } = useServicesContext();

  const sourceIntervention = useSourceIntervention();
  const [loading, setLoading] = useState(false);

  const { addToast } = useToast();

  const {
    algorithmType,
    sampleId,
    subject,
    isDraft,
    isCloning,
    isLoading,
    definitionRef,
    participantsRef,
    nudgePolicyRef,
    metricsPolicyRef,
    schedulingRef,
    markovStatesRef,
    groupFiltersRef,
    setReadiness,
    readiness,
    skipClonning,
  } = useInterventionContext();

  useEffect(() => {
    return () => interventionService.stopDraft();
  }, []);

  const buildNewInterventionObject = (): NewInterventionRequest => {
    const { name, description, algorithm } = definitionRef.current.value();

    const request: NewInterventionRequest = {
      intervention: {
        name,
        description,
        participant_policy: participantsRef.current?.value().participants,
        nudge_policy: nudgePolicyRef.current?.value(),
        metric_policy: metricsPolicyRef.current?.value(),
        algo_policy: {
          ...algorithm,
        },
      },
      schedule: schedulingRef.current?.value(),
    };

    if (algorithmType === AlgorithmType.RestlessBandit && algorithm && algorithm.spec !== undefined) {
      (request.intervention.algo_policy.spec as AlgorithmSpec).markov = markovStatesRef.current.value();
      (request.intervention.algo_policy.spec as AlgorithmSpec).social_group_filters = groupFiltersRef.current.value();
    }

    return request;
  };

  const handleSaveDraft = async () => {
    const newInterventionRequest = buildNewInterventionObject();

    await interventionService.saveDraft(newInterventionRequest.intervention, newInterventionRequest.schedule);
  };

  // note: debounce returns a function
  const debouncedSaveDraft = useCallback(debounce(handleSaveDraft, 2000), []);

  const handleCreateIntervention = async () => {
    const newInterventionRequest = buildNewInterventionObject();

    try {
      setLoading(true);
      await interventionService.create(newInterventionRequest.intervention, newInterventionRequest.schedule);

      addToast('Intervention created', ToastType.SUCCESS, 5000);

      navigate(CFRoutes.intervention);
    } catch (err) {
      addToast(`Impossible to create intervention ${(err as any).message}`, ToastType.ERROR, 5000);
      setLoading(false);

      console.log('Error creating intervention: ', err);
    }
  };

  const updateReadiness = useCallback(
    (isReady: boolean, step: Steps) => {
      debouncedSaveDraft();

      setReadiness((readiness) => ({ ...readiness, [step]: isReady }));
    },
    [debounce, readiness]
  );

  if (isLoading) {
    return <div></div>;
  }

  if (isDraft && !sourceIntervention) {
    // wait to avoid initialization problems in CFSplitRatios
    return <div></div>;
  }

  if (isCloning && !sourceIntervention && !skipClonning) {
    // wait to avoid initialization problems in CFSplitRatios
    return <div></div>;
  }

  if (algorithmType === AlgorithmType.Bandit) {
    return <NewContextBanditIntervention sourceIntervention={sourceIntervention} updateReadiness={updateReadiness} />;
  }

  if (algorithmType === AlgorithmType.BanditV2) {
    return <NewContextBanditV2Intervention sourceIntervention={sourceIntervention} updateReadiness={updateReadiness} />;
  }

  if (algorithmType === AlgorithmType.ABTest) {
    return <NewABIntervention updateReadiness={updateReadiness} />;
  }

  return (
    <div className="intervention">
      <Wizard>
        <Wizard.Header>
          <CFNavList
            titles={allowedTabs()}
            selected={Tabs.Interventions}
            onClick={(selectedTab) => navigate(interventionByTab[selectedTab])}
          />
        </Wizard.Header>

        <Wizard.Step name={Steps.Definition} ready={readiness.definition}>
          <InterventionDefinition
            ref={definitionRef}
            modelService={modelService}
            defaultValue={
              sourceIntervention && {
                name: sourceIntervention.intervention.name,
                description: sourceIntervention.intervention.description,
                algorithm: sourceIntervention.intervention.algo_policy,
              }
            }
            onReady={(thisReady) => updateReadiness(thisReady, Steps.Definition)}
          />
        </Wizard.Step>

        <Wizard.Step name={Steps.Nudge} ready={readiness.nudge} displayName={StepsDisplayNames[Steps.Nudge]}>
          <InterventionNudgePolicy
            nudgeService={nudgeService}
            traitService={traitService}
            defaultValue={sourceIntervention && sourceIntervention.intervention.nudge_policy}
            ref={nudgePolicyRef}
            onReady={(thisReady) => updateReadiness(thisReady, Steps.Nudge)}
          />
        </Wizard.Step>

        <Wizard.Step
          name={Steps.Participants}
          ready={readiness.participants}
          displayName={StepsDisplayNames[Steps.Participants]}
        >
          <InterventionParticipantsRestless
            ref={participantsRef}
            traitService={traitService}
            cohortService={cohortService}
            defaultValue={
              sourceIntervention && {
                cohort: sourceIntervention.cohort,
                extra_filters: sourceIntervention.intervention.participant_policy.static_sample.extra_filters,
                splitRatio: sourceIntervention.intervention.algo_policy.split_ratio,
                sampleCount: sourceIntervention.sampleSize || 0,
              }
            }
            onReady={(thisReady) => updateReadiness(thisReady, Steps.Participants)}
          />
        </Wizard.Step>

        <Wizard.Step name={Steps.States} ready={readiness.states}>
          <MarkovStates ref={markovStatesRef} onReady={(thisReady) => updateReadiness(thisReady, Steps.States)} />
        </Wizard.Step>

        <Wizard.Step
          name={Steps.GroupTraits}
          ready={readiness[Steps.GroupTraits]}
          displayName={StepsDisplayNames[Steps.GroupTraits]}
        >
          <GroupTraits ref={groupFiltersRef} onReady={(thisReady) => updateReadiness(thisReady, Steps.GroupTraits)} />
        </Wizard.Step>

        <Wizard.Step name={Steps.Score} ready={readiness[Steps.Score]}>
          <AdvantageScore />
        </Wizard.Step>

        <Wizard.Step name={Steps.Metrics} ready={readiness.metrics}>
          <InterventionRestlessMetricsPolicy
            ref={metricsPolicyRef}
            sampleId={sampleId.value}
            subject={subject}
            cohortService={cohortService}
            traitService={traitService}
            onReady={(thisReady) => updateReadiness(thisReady, Steps.Metrics)}
          />
        </Wizard.Step>

        <Wizard.Step name={Steps.Scheduling} ready={readiness.scheduling}>
          <InterventionSection
            name={Steps.Scheduling}
            title="Scheduling"
            subtitle="Set schedules for your intervention."
          >
            <SchedulingBuilder
              defaultValue={sourceIntervention?.schedule}
              ref={schedulingRef}
              onReady={(thisReady) => updateReadiness(thisReady, Steps.Scheduling)}
            />
          </InterventionSection>
        </Wizard.Step>

        <Wizard.Footer>
          <div className="controls">
            <CFButton
              value="Create Intervention"
              role={CFRole.Primary}
              disabled={Object.values(readiness).some((ready) => !ready)}
              isLoading={loading}
              onClick={handleCreateIntervention}
              iconCustom={<img src={pagePlusIcon} />}
            />
          </div>
        </Wizard.Footer>
      </Wizard>
    </div>
  );
};

export default NewIntervention;
