import React, { CSSProperties, ReactNode, useCallback, useEffect, useState } from 'react';
import dayjs from 'dayjs';
import classNames from 'classnames';
import isEqual from 'lodash.isequal';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro'; // <-- import styles to be used

import { getValueByPath } from 'helpers/misc';

import './cf-table.scss';
import StatusTag, { StatusTagVariant } from 'components/StatusTag';

export enum TableType {
  Primary = 'table-primary',
  Secondary = 'table-secondary',
}

interface Props {
  title?: string;
  headers: Column[];
  data: Record<string, any>[];
  indexCol?: string;
  emptyLabel?: string;
  multiSelect?: boolean;
  selected?: Record<string, any>[];
  variant?: TableType;
  skipHeader?: boolean;
  maxHeight?: number;
  onSelectRow?: (row: Record<string, any>) => void;
}

export enum ColumnType {
  STRING = 'string',
  NUMBER = 'number',
  DATE = 'date',
  OBJECT = 'object',
  BOOLEAN = 'boolean',
}

export interface Column {
  title: string;
  field: string;
  expandable?: boolean;
  type: ColumnType;
  renderCell?: (row: Record<string, any>, column: Column, indexCol: string) => ReactNode;
  renderHeader?: (column: Column) => ReactNode;
  style?: CSSProperties;
}

const CFTable = ({
  title,
  data,
  headers,
  indexCol = 'id',
  multiSelect = false,
  emptyLabel = 'No available data',
  maxHeight,
  selected = [],
  variant = TableType.Primary,
  skipHeader = false,
  onSelectRow,
}: Props) => {
  const [selectedRows, setSelectedRows] = useState<Record<string, any>[]>(selected);
  const [expandedRows, setExpandedRows] = useState<Record<string, boolean>>({});

  const handleClickedRow = (evt: React.MouseEvent<HTMLElement>, row: Record<string, any>) => {
    handleSelectRow(row);

    if (headers.find((header) => header.expandable)) {
      handleExpandRow(evt, row);
    }
  };

  const handleSelectRow = (row: Record<string, any>) => {
    const rowId = row[indexCol];
    const isSelected = selectedRows.find((selectedRow) => selectedRow[indexCol] === rowId);

    if (isSelected) {
      setSelectedRows((prevSelectRows) => prevSelectRows.filter((selectedRow) => selectedRow[indexCol] !== rowId));
    } else {
      if (multiSelect) {
        setSelectedRows((prevRow) => [...prevRow, row]);
      } else {
        setSelectedRows([row]);
      }
    }

    onSelectRow?.(row);
  };

  const handleExpandRow = useCallback(
    (evt: React.MouseEvent<HTMLElement>, row: Record<string, any>) => {
      evt.stopPropagation();
      const rowId = row[indexCol];

      const newExpandedRows = { ...expandedRows };

      if (expandedRows[rowId]) {
        delete newExpandedRows[rowId];
      } else {
        newExpandedRows[rowId] = true;
      }

      setExpandedRows(newExpandedRows);
    },
    [expandedRows]
  );

  useEffect(() => {
    if (selected) {
      if (!isEqual(selected, selectedRows)) setSelectedRows(selected);
    }
  }, [selected]);

  const formatCellContent = (row: Record<string, any>, column: Column) => {
    const content = getValueByPath(row, column.field);

    if (column.renderCell) {
      return column.renderCell(row, column, indexCol);
    }

    if (column.type === ColumnType.BOOLEAN) {
      return <StatusTag label={`${content}`} variant={content ? StatusTagVariant.Success : StatusTagVariant.Failed} />;
    }

    if (column.type === ColumnType.OBJECT && !content) {
      return <div className="table-cell"></div>;
    }

    if (column.type === ColumnType.OBJECT && content.length === undefined) {
      return Object.entries((entry: any) => {
        return <div className="table-cell">{`${entry[0]}: ${entry[1]} <br />`}</div>;
      });
    }

    if (column.type === ColumnType.OBJECT && content.length !== undefined) {
      return <div className="table-cell">{content.join(', ')}</div>;
    }

    if (column.type === ColumnType.DATE) {
      if (!content) {
        return <div className="table-cell">-</div>;
      }
      return <div className="table-cell">{dayjs(content).format('YYYY-MM-DD HH:mm:ss')}</div>;
    }

    return <div className="table-cell">{content}</div>;
  };

  const formatTableHeader = (column: Column) => {
    if (column.renderHeader) return column.renderHeader(column);
    else return <div className="table-cell">{column.title}</div>;
  };

  return (
    <section className={classNames('cf-table', variant)}>
      <div className="cf-table-fix-head" style={{ maxHeight: maxHeight }}>
        {title && <header> {title} </header>}
        <table>
          {!skipHeader && (
            <thead>
              <tr>
                {headers.map((header: Column, i) => (
                  <th key={i} style={header.style}>
                    {formatTableHeader(header)}
                  </th>
                ))}
              </tr>
            </thead>
          )}
          {!!data.length && (
            <tbody className="cf-table-body">
              {data.map((row: any) => {
                return (
                  <React.Fragment key={row[indexCol]}>
                    <tr
                      key={row[indexCol]}
                      onClick={(evt) => handleClickedRow(evt, row)}
                      className={classNames({
                        'row-selected': selectedRows.find((selectedRow) => selectedRow[indexCol] === row[indexCol]),
                        'row-expanded': !!expandedRows[row[indexCol]],
                      })}
                    >
                      {headers
                        .filter((header) => !header.expandable)
                        .map((header: Column, i) => (
                          <td key={i} style={header.style}>
                            {formatCellContent(row, header)}
                          </td>
                        ))}

                      {!!headers.find((header) => header.expandable) && (
                        <td onClick={(evt) => handleExpandRow(evt, row)}>
                          {expandedRows[row[indexCol]] ? (
                            <FontAwesomeIcon icon={icon({ name: 'angle-up', style: 'solid' })} />
                          ) : (
                            <FontAwesomeIcon icon={icon({ name: 'angle-down', style: 'solid' })} />
                          )}
                        </td>
                      )}
                    </tr>
                    {expandedRows[row[indexCol]] && (
                      <tr className="expanded">
                        <td colSpan={headers.filter((header) => !header.expandable).length + 1}>
                          {formatCellContent(row, headers.find((header) => header.expandable) as Column)}
                        </td>
                      </tr>
                    )}
                  </React.Fragment>
                );
              })}
            </tbody>
          )}
        </table>
        {!data.length && <div className="cf-empty-table-label">{emptyLabel}</div>}
      </div>
    </section>
  );
};

export default CFTable;
