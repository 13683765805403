import React, { useCallback, useEffect, useMemo, useState } from 'react';
import dayjs from 'dayjs';

import { displayDate, Granularity, timeConverter, timeConverterFromNanos } from 'helpers/dates';

import { StartEndPair } from 'services/intervention/intervention.types';

import CFInput, { CFInputType } from 'components/CFInput';
import DatetimePicker from 'components/DateTime/DatetimePicker';
import TimePicker from 'components/DateTime/TimePicker';
import CFTrashButton from 'components/buttons/CFTrashButton';
import CFSelect, { Option } from 'components/CFSelect';

import { useSchedulingContext } from '../context/useSchedulingContext';

import './schedule-set.scss';

const repeatOptions = [
  {
    label: 'Month',
    value: Granularity.Month,
  },
  {
    label: 'Day',
    value: Granularity.Day,
  },
  {
    label: 'Hour',
    value: Granularity.Hour,
  },
];

const repeatOptionMinutes = {
  label: 'Minute',
  value: Granularity.Minute,
};

const numOfRepsToInterval = (numOfReps: number, granularity: Granularity) => {
  return timeConverter[granularity](numOfReps);
};

interface Props {
  defaultValue?: StartEndPair;
  id: number;
  onRemove: () => void;
  onChange: (pair: StartEndPair) => void;
  timezone?: string;
  editable?: boolean;
  color: string;
}

const ScheduleSet = ({
  id,
  onRemove,
  onChange,
  timezone = dayjs.tz.guess(),
  defaultValue,
  color,
  editable = true,
}: Props): JSX.Element => {
  const defaultStartDate = defaultValue?.start && dayjs(defaultValue.start).toDate();
  const defaultStartTime = defaultValue?.start && dayjs(defaultValue.start).format('HH:mm');
  const defaultEndDate = defaultValue?.end && dayjs(defaultValue.end).toDate();
  const { granularity } = useSchedulingContext();

  const [numOfReps, setNumOfReps] = useState(() => {
    if (!defaultValue) {
      return 1;
    }

    const granularities = [Granularity.Month, Granularity.Day, Granularity.Hour, Granularity.Minute];

    for (const granularity of granularities) {
      const unitGranularity = timeConverterFromNanos[granularity](defaultValue.dur);

      if (unitGranularity >= 1) {
        return unitGranularity;
      }
    }

    return 1;
  });

  const [repeatOption, setRepeatOption] = useState(() => {
    if (!defaultValue) {
      return repeatOptions[1];
    }

    if (granularity) {
      return { label: granularity, value: granularity };
    }

    const granularities = [Granularity.Month, Granularity.Day, Granularity.Hour, Granularity.Minute];

    for (const granularity of granularities) {
      const unitGranularity = timeConverterFromNanos[granularity](defaultValue.dur);

      if (unitGranularity >= 1) {
        return { label: granularity, value: granularity };
      }
    }

    return repeatOptions[1];
  });

  const [startDate, setStartDate] = useState(defaultStartDate || new Date());
  const [startTime, setStartTime] = useState(defaultStartTime || '');

  const [endDate, setEndDate] = useState(defaultEndDate || new Date());
  const [endTime, setEndTime] = useState(defaultStartTime || '');

  const [isNeverEnding] = useState(false);

  useEffect(() => {
    if (!granularity) {
      return;
    }

    setRepeatOption({ label: granularity, value: granularity });
  }, [granularity]);

  useEffect(() => {
    if (!defaultValue) {
      return;
    }

    const granularities = [Granularity.Month, Granularity.Day, Granularity.Hour, Granularity.Minute];

    for (const granularity of granularities) {
      const unitGranularity = timeConverterFromNanos[Granularity.Month](defaultValue.dur);

      if (unitGranularity >= 1) {
        setNumOfReps(unitGranularity);
        setRepeatOption({ label: granularity, value: granularity });

        return;
      }
    }
  }, []);

  const handleRepsChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const number = Number(e.target.value);

    if (Number.isNaN(number) || !Number.isInteger(number) || number < 1) {
      setNumOfReps(1);
      return;
    }

    setNumOfReps(number);
  }, []);

  useEffect(() => {
    onChange({
      start: displayDate(startDate, startTime, true, false, timezone, 'YYYY-MM-DDTHH:mm:ss.SSSZ'),
      dur: numOfRepsToInterval(numOfReps, repeatOption.value),
      ...(!isNeverEnding && {
        end: displayDate(endDate, endTime, true, false, timezone, 'YYYY-MM-DDTHH:mm:ss.SSSZ'),
      }),
    });
  }, [numOfReps, repeatOption, startDate, startTime, endDate, endTime, isNeverEnding, timezone]);

  const handleRepeatChangeFromSelect = useCallback((option: Option) => {
    setRepeatOption({ label: option.label, value: option.value as Granularity });
  }, []);

  const granularityOptions = useMemo(() => {
    if (granularity) {
      return [{ label: granularity, value: granularity }];
    }

    return [...repeatOptions, repeatOptionMinutes];
  }, [granularity]);

  return (
    <div className="scheduling-set">
      <div className="set-title">
        Schedule Set {id} <span className="set-title__legend" style={{ backgroundColor: color }}></span>
      </div>
      <div className="text-md">Repeat every</div>
      <div className="row">
        <div className="small-input">
          <CFInput
            disabled={!editable || granularity !== undefined}
            type={CFInputType.Number}
            defaultValue={numOfReps}
            onChange={handleRepsChange}
            min={1}
          />
        </div>

        <CFSelect
          disabled={!editable || granularity !== undefined}
          options={granularityOptions}
          value={repeatOption}
          onSelected={handleRepeatChangeFromSelect}
        />
        {editable && <CFTrashButton onClick={onRemove} />}
      </div>
      <div className="row">
        <div className="group">
          <div className="text-md">Start date</div>
          <DatetimePicker initialDate={defaultStartDate || undefined} onChange={setStartDate} disabled={!editable} />
        </div>
        <div className="group">
          <div className="text-md">Start time</div>
          <TimePicker defaultValue={startTime} onChange={setStartTime} disabled={!editable} />
        </div>
      </div>
      <div className="row">
        <div className="group">
          <div className="text-md">End date</div>
          <DatetimePicker initialDate={defaultEndDate || undefined} disabled={isNeverEnding} onChange={setEndDate} />
        </div>

        <div className="group">
          <div className="text-md">End time</div>
          <TimePicker defaultValue={endTime} onChange={setEndTime} disabled={!editable} />
        </div>
      </div>
    </div>
  );
};

export default ScheduleSet;
