import React, { useEffect } from 'react';
import { forwardRef, useCallback, useImperativeHandle, useRef, useState } from 'react';
import ContentEditable from 'react-contenteditable';

import { useServicesContext } from 'hooks/useServicesContext';

import { Trait } from 'domain/traits.types';

import { replaceTraitCodesWithDisplayName, sanitizeHtml, templateToHtml } from 'services/nudging/templateParsing';
import { createTraitCode, getTraitName } from 'services/traits/helpers.traits';
import { Ptr } from 'services/cohort/cohort.types.api';
import { Message, TemplateType } from 'services/nudging/nudge.types';

import CFInput from 'components/CFInput';
import CFTitledComponent from 'components/CFTitledComponent';
import CFSelect, { Option } from 'components/CFSelect';

import TraitsConfiguration, { TraitsConfigurationRef } from './TraitsConfiguration';
import ItemPairConfiguration, { ItemPairConfigurationRef } from './ItemPairConfiguration';

import './nudge-message-form.scss';

interface NewNudgeFormProps {
  onMessageChange: (message: string) => void;
  onTitleChange: (title: string) => void;
  defaultMessage?: Partial<Message>;
}

interface NudgeMessage {
  message: Partial<Message>;
}

export interface NudgeMessageRef {
  value: () => NudgeMessage;
}

const TemplateTypeLabels = {
  [TemplateType.None]: 'No recommendation',
  [TemplateType.TraitsTemplate]: 'Trait',
  [TemplateType.ItemPairTemplate]: 'Item pair',
  [TemplateType.RandomItemPairTemplate]: 'Random item pair',
};

const NudgeMessageForm = forwardRef<NudgeMessageRef, NewNudgeFormProps>(function NewNudgeMessageForm(
  { onMessageChange, onTitleChange, defaultMessage }: NewNudgeFormProps,
  ref
) {
  const { nudgeService, traitSessionService: traitService } = useServicesContext();
  const titleRef = useRef<HTMLInputElement | null>(null);
  const bodyRef = useRef<HTMLDivElement | null>(null);
  const itemPairConfig = useRef<ItemPairConfigurationRef | null>(null);
  const traitsConfig = useRef<TraitsConfigurationRef | null>(null);
  const [traits, setTraits] = useState<Trait[]>([]);
  const [initialized, setInitialized] = useState(false);

  const [recommendationTypeOption, setRecommendationTypeOption] = useState<Option>(() => {
    if (!defaultMessage || !defaultMessage.tmpl_cfg?.tmpl_type) {
      return {
        value: TemplateType.None,
        label: TemplateTypeLabels[TemplateType.None],
      };
    }

    return {
      value: defaultMessage.tmpl_cfg.tmpl_type,
      label: TemplateTypeLabels[defaultMessage.tmpl_cfg.tmpl_type],
    };
  });

  const [content, setContent] = useState<string>('');

  const traitCodes = traits.reduce<Record<string, Trait>>((acc, cur) => {
    acc[createTraitCode(cur)] = cur;

    return acc;
  }, {});

  useEffect(() => {
    (async () => {
      const traits = (await nudgeService.getAvailableTraits()).filter((trait) => {
        const name = getTraitName(trait);

        return name !== 'id';
      });
      setTraits(traits);
    })();
  }, [traitService]);

  useImperativeHandle(ref, () => ({
    value() {
      const nudgeMessage: NudgeMessage = {
        message: {
          title: titleRef.current?.value || '',
          body: bodyRef.current?.textContent || '',
        },
      };

      if (recommendationTypeOption.value === TemplateType.ItemPairTemplate) {
        nudgeMessage.message.tmpl_cfg = itemPairConfig.current?.value();
      }

      if (recommendationTypeOption.value === TemplateType.TraitsTemplate) {
        nudgeMessage.message.tmpl_cfg = traitsConfig.current?.value();
      }

      return nudgeMessage;
    },
  }));

  const handleRecommendationSelected = async (option: Option) => {
    setRecommendationTypeOption(option);

    if (option.value === TemplateType.ItemPairTemplate || option.value === TemplateType.RandomItemPairTemplate) {
      // ask the service to add this info

      setContent(templateToHtml(' {{ primary }} {{ secondary }} ', ['primary', 'secondary']));
    } else {
      setContent('');
    }
  };

  useEffect(() => {
    if (!defaultMessage || initialized) {
      return;
    }

    const keywords =
      recommendationTypeOption.value === TemplateType.ItemPairTemplate
        ? ['primary', 'secondary']
        : Object.keys(traitCodes);

    const input = sanitizeHtml(defaultMessage.body || '');
    const html = templateToHtml(input, keywords);

    setContent(html);
    setInitialized(true);
  }, [defaultMessage, recommendationTypeOption, traitCodes, initialized]);

  const handleMessageChange = useCallback(() => {
    const keywords =
      recommendationTypeOption.value === TemplateType.ItemPairTemplate
        ? ['primary', 'secondary']
        : Object.keys(traitCodes);

    const input = sanitizeHtml(bodyRef.current?.textContent || '');
    const html = templateToHtml(input, keywords);

    setContent(html);

    onMessageChange(replaceTraitCodesWithDisplayName(bodyRef.current?.textContent || '', traits));
  }, [traitCodes]);

  const handleTitleChange = useCallback(() => {
    onTitleChange(titleRef.current?.value || '');
  }, []);

  const handleTraitSelected = useCallback(
    async (ptr: Ptr) => {
      setContent((content) => `${content} ${templateToHtml(`{{ ${ptr} }}`, Object.keys(traitCodes))}`);
    },
    [content, traitCodes]
  );

  useEffect(() => {
    onMessageChange(bodyRef.current?.textContent || '');
  }, [content]);

  return (
    <div className={`new-nudge-form`}>
      <CFTitledComponent title="Title">
        <CFInput
          ref={(el) => (titleRef.current = el)}
          onChange={handleTitleChange}
          defaultValue={defaultMessage?.title}
        />
      </CFTitledComponent>

      <CFTitledComponent title="Recommendation">
        <CFSelect
          testId="nudge-message-form-template-type-select"
          onSelected={handleRecommendationSelected}
          options={[
            { label: TemplateTypeLabels[TemplateType.None], value: TemplateType.None },
            { label: TemplateTypeLabels[TemplateType.TraitsTemplate], value: TemplateType.TraitsTemplate },
            { label: TemplateTypeLabels[TemplateType.ItemPairTemplate], value: TemplateType.ItemPairTemplate },
          ]}
          value={recommendationTypeOption}
        />
      </CFTitledComponent>

      {recommendationTypeOption.value === TemplateType.TraitsTemplate && (
        <TraitsConfiguration
          ref={traitsConfig}
          traits={traits}
          onTraitSelected={handleTraitSelected}
          defaultValue={defaultMessage?.tmpl_cfg}
        />
      )}

      {recommendationTypeOption.value === TemplateType.ItemPairTemplate && (
        <ItemPairConfiguration ref={itemPairConfig} defaultValue={defaultMessage?.tmpl_cfg} />
      )}

      <CFTitledComponent title="Custom message">
        <ContentEditable
          id="form-message-body-input"
          role="textbox"
          innerRef={bodyRef}
          html={content}
          onChange={handleMessageChange}
          className="editable-area"
        />
      </CFTitledComponent>
    </div>
  );
});

export default NudgeMessageForm;
