import React, { useCallback, useEffect, useState } from 'react';
import { createSearchParams } from 'react-router-dom';
import { faRefresh } from '@fortawesome/free-solid-svg-icons';

import dayjs from 'dayjs';

import { useServicesContext } from 'hooks/useServicesContext';
import useCFNavigation, { sessionQueryInfo } from 'hooks/useCFNavigation';
import { useToast } from 'hooks';

import { CFRole } from 'domain/general.types';

import KeyValue from 'views/intervention/components/KeyValue';

import { AuthAction, isAllowedTo } from 'services/authorization.service';
import { subject, remove as removeCohort } from 'services/cohort/cohort.repo';
import Cohort from 'services/cohort/domain/Cohort';

import CFPaginatedList from 'components/CFPaginatedList';
import CFConfirmableButton from 'components/CFConfirmableButton';
import { CFNavList, NavListAction } from 'components/CFNavList';
import TreeViewer from 'connected-components/TreeViewer';
import CFCloneButton from 'components/buttons/CFCloneButton';
import { ToastType } from 'components/CFToast/types';
import CFTrashButton from 'components/buttons/CFTrashButton';
import CFButton from 'components/buttons/CFButton';

import { CFRoutes } from 'routes';

import { useCohortsContext } from '../context.cohort';

import './cohort-detail.scss';

interface Props {
  cohort: Cohort;
  removable: boolean;
  usedInLanding: boolean;
  onRemove: (id: number) => void;
}

enum CohortSections {
  Definition = 'definition',
  User = 'user',
}

const sections = [CohortSections.Definition, CohortSections.User];

const CohortDetail = ({ cohort, removable, onRemove }: Props) => {
  const { addToast } = useToast();
  const navigate = useCFNavigation();

  const { landingCohort, setCohortForLanding } = useCohortsContext();
  const { backfillService } = useServicesContext();

  const [selectedSection, setSelectedSection] = useState<string>(sections[0]);

  const [totalSubjects, setTotalSubjects] = useState(-1);
  const [subjects, setSubjects] = useState<string[]>([]);

  const [curPage, setCurPage] = useState<number>(0);
  const [curPageSize, setCurPageSize] = useState<number>(10);

  const downloadSubjectPage = useCallback(async () => {
    const subjects = await subject(cohort.id, curPage, curPageSize);
    setTotalSubjects(subjects.total);
    setSubjects(subjects.data || []);
  }, [cohort, curPage, curPageSize]);

  const handleNewPageRequest = (page: number, size: number) => {
    setCurPage(page - 1);
    setCurPageSize(size);
  };

  const handleDeleteCohort = async () => {
    try {
      await removeCohort(cohort.id);
      onRemove(cohort.id);
      addToast(`Cohort successefully removed`, ToastType.SUCCESS);
    } catch (err) {
      console.error('Error deleting cohort: ', err);
      addToast(`Counldn't remove cohort`, ToastType.ERROR, 5000);
    }
  };

  const handleBackfill = async () => {
    backfillService.backfillCohort(cohort.id);
  };

  const handleSetCohortForLanding = useCallback(() => {
    setCohortForLanding(cohort);
  }, []);

  useEffect(() => {
    downloadSubjectPage();
  }, [downloadSubjectPage]);

  const actions = [];

  if (isAllowedTo(AuthAction.Backfill)) {
    actions.push(
      <NavListAction key="backfill_cohort">
        <CFButton
          onClick={handleBackfill}
          disabled={false}
          animate={false}
          description="Recompute values"
          value=""
          iconName={faRefresh}
          role={CFRole.OnlyIcon}
          testId={`cohort-backfill`}
        />
      </NavListAction>
    );
  }

  if (isAllowedTo(AuthAction.DeleteCohort)) {
    actions.push(
      <NavListAction key="clone_cohort_action">
        <CFCloneButton
          onClick={() =>
            navigate({
              pathname: CFRoutes.segmentation_cohort_new,
              search: `?${createSearchParams({
                subject: cohort.subject_type,
                from: `${cohort.id}`,
                ...sessionQueryInfo(),
              })}`,
            })
          }
        />
      </NavListAction>
    );
  }

  if (removable && isAllowedTo(AuthAction.DeleteCohort)) {
    actions.push(
      <NavListAction>
        <CFConfirmableButton title="Delete cohort" question="Are you sure you want to remove this cohort?">
          <CFTrashButton onClick={() => handleDeleteCohort()} />
        </CFConfirmableButton>
      </NavListAction>
    );
  }

  if (cohort.id !== landingCohort && isAllowedTo(AuthAction.UpdateLandingCohort)) {
    actions.push(
      <NavListAction>
        <CFConfirmableButton title="Use in landing" question="Are you sure you want to use this cohort in landing">
          <CFButton value={'Set to Landing'} onClick={handleSetCohortForLanding} />
        </CFConfirmableButton>
      </NavListAction>
    );
  }

  return (
    <div className="cohort-detail">
      <CFNavList titles={sections} selected={selectedSection} onClick={setSelectedSection} style="no-underline">
        {actions}
      </CFNavList>

      {selectedSection === CohortSections.Definition && (
        <React.Fragment>
          <div className="cohort-detail-info">
            <KeyValue name="Description" value={cohort.description} forceBreakline />
            <KeyValue name="Creation Date" value={dayjs(cohort.created_at).format('DD-MM-YYYY')} />
            <KeyValue name="Created by" value={cohort.created_by} />
          </div>

          <TreeViewer value={cohort.tree} />
        </React.Fragment>
      )}

      {selectedSection === CohortSections.User && (
        <CFPaginatedList total={totalSubjects} onPageChange={handleNewPageRequest}>
          {subjects.map((subject) => (
            <div className="cohort-user" key={subject}>
              {' '}
              {subject}{' '}
            </div>
          ))}
        </CFPaginatedList>
      )}
    </div>
  );
};

export default CohortDetail;
